import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    getSearchResults({ getters }, params) {
        params.SPID = getters.spid;
        return axios.get(`/v2/:spid/customer`, { params }).then(({apidata}) => {
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}