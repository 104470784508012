import { extend } from 'vee-validate';

function lastName() {
    extend("lastName", {
        validate(value) {
            const regex = new RegExp("^(?=.{1,16}$)[a-zA-Z]+((\ |\'|\-)?[a-z A-Z])*$");
            return regex.test(value);
        },
        message: 'Last name should contain not more than 16 letters. Space, dash, apostrophe allowed.',
    });
}

export default lastName