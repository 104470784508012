import { extend } from 'vee-validate';

function msisdn() {
    extend("msisdn", {
        validate(value) {
            const regex = new RegExp(/^\+?(02|642)\d{7,}$/);
            return regex.test(value);
        },
        message: 'MSISDN should start with 02 or 642 and contains at least 7 numeric characters.',
    });
}

export default msisdn