<template>
    <div class="app-spinner">
        <b-spinner></b-spinner>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.app-spinner {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
}
</style>