// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: reporting.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
};

const mutations = {
};

const actions = {
	//
	// Service: Reporting
	// 
	/**
	* RPC Method: Dashboard
	* Description: Returns real-time reporting information suitable for displaying on a service provider dashboard.
	* HTTP Method: GET
	* API Path: /${req.SPID}/dashboard
	* @param    {DashboardRequest} req The API payload object (DashboardRequest)
	* @return   {DashboardResponse} The API response object (DashboardResponse)
	*
	* @typedef  {Object}  DashboardRequest
	* @property {Number}  SPID       
	* @property {Boolean} Complete   If you are service provider 1, you can, set Complete to true to get a report for all, service providers.
	* @property {Number}  Hours      
	* @property {Number}  Days       
	* @property {Number}  Weeks      
	* @property {Number}  Fortnights 
	* @property {Number}  Months     
	* @property {Number}  Years      
	*
	* @typedef  {Object}  DashboardResponse
	* @property {Customers[]} Customers 
	* @property {Orders[]} Orders    
	* @property {Products[]} Products  
	*
	*/
	Dashboard({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/${paramSPID}/dashboard`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('Dashboard ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('Dashboard ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('Dashboard ERROR:', error)
					return Promise.reject(error)
				}
				console.log('Dashboard UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in Dashboard'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};