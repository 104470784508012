/**
 * The file enables `@/router/permissions/index.js` to import all router permission rules
 * in a one-shot manner. There should not be any reason to edit this file.
 */

const files = require.context('.', false, /\.js$/)
const rules = {}

files.keys().forEach(key => {
    if (key === './index.js') return
    rules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default rules
