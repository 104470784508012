const isTouchDevice = () => {
    if (
        "ontouchstart" in window ||
        (window.DocumentTouch && document instanceof DocumentTouch)
    ) {
        return true
    }

    const prefixes = " -webkit- -moz- -o- -ms- ".split(" ")

    const mq = function(query) {
        return window.matchMedia(query).matches
    }

    const query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("")

    return mq(query)
}

export default isTouchDevice