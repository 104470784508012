// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: mobileproduct.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	MobileProducts: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PrepayBalances: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getMobileProductByUUID: state => (UUID) => (state.MobileProducts.UUIDs[ UUID ] && !state.MobileProducts.UUIDs[ UUID ].expired()) ?  state.MobileProducts.UUIDs[ UUID ].hashedData() : null,
	getPrepayBalanceByUUID: state => (UUID) => (state.PrepayBalances.UUIDs[ UUID ] && !state.PrepayBalances.UUIDs[ UUID ].expired()) ?  state.PrepayBalances.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveMobileProduct saves a single MobileProduct object
	// into both the StoreHash cache and the VueX store
	mutSaveMobileProduct(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.MobileProducts.UUIDs)) {
			state.MobileProducts.UUIDs[ obj.UUID ] = new StoreHash(state.MobileProducts.lifetime)
		}
		state.MobileProducts.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveMobileProductByUUID removes a MobileProduct object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveMobileProductByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.MobileProducts.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveMobileProducts saves a paginated response of MobileProducts
	mutSaveMobileProducts(state, { Response }) {
		if (!Response || !Array.isArray(Response.MobileProducts)) { return } // Must have Response object with results
		for (let i = 0; i < Response.MobileProducts.length; i++) {
			if (!(Response.MobileProducts[i].UUID in state.MobileProducts.UUIDs)) {
				state.MobileProducts.UUIDs[ Response.MobileProducts[i].UUID ] = new StoreHash(state.MobileProducts.lifetime)
			}
			state.MobileProducts.UUIDs[ Response.MobileProducts[i].UUID ].fix(Response.MobileProducts[i])  // Add single MobileProduct to StoreHash
		}
	},
	// mutSavePrepayBalance saves a single PrepayBalance object
	// into both the StoreHash cache and the VueX store
	mutSavePrepayBalance(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PrepayBalances.UUIDs)) {
			state.PrepayBalances.UUIDs[ obj.UUID ] = new StoreHash(state.PrepayBalances.lifetime)
		}
		state.PrepayBalances.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePrepayBalanceByUUID removes a PrepayBalance object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePrepayBalanceByUUID(state, UUID) {
		if (!UUID) { return } // Must have UUID to Remove
		delete state.PrepayBalances.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedMobileProductByUUID fetches a MobileProduct from the cache
	// and if not in the cache, fetches it from the API
	getCachedMobileProductByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.MobileProducts.UUIDs[ UUID ] && !state.MobileProducts.UUIDs[ UUID ].expired()) {			
			return state.MobileProducts.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetMobileProductByUUID', { UUID }).then(respPayload => {
			commit('mutSaveMobileProduct', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveMobileProductByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPrepayBalanceByUUID fetches a PrepayBalance from the cache
	// and if not in the cache, fetches it from the API
	getCachedPrepayBalanceByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PrepayBalances.UUIDs[ UUID ] && !state.PrepayBalances.UUIDs[ UUID ].expired()) {			
			return state.PrepayBalances.UUIDs[ UUID ].hashedData() // Return the cached record
		}
		// Fetch from the API
		return dispatch('GetPrepayBalanceByUUID', { UUID }).then(respPayload => {
			commit('mutSavePrepayBalance', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePrepayBalanceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: MobileRPC
	// 
	/**
	* RPC Method: ChangeMSISDN
	* Description: ## ChangeMSISDN
	* 
	* The change MSISDN feature allows a mobile subscriber that has an active mobile service to change the primary MSISDN
	* that was associated with their subscription at time of initial order.
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |MSISDN|MSISDN(string)|Change the MSISDN this product has|
	* 
	* ### Example Request
	* 
	*     {
	*        "MSISDN": "6401150310635192",
	*     }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/msisdn_change
	* @param    {MobileMSISDNChangeRequest} req The API payload object (MobileMSISDNChangeRequest)
	* @return   {MSISDNChangeResponse} The API response object (MSISDNChangeResponse)
	*
	* @typedef  {Object}  MobileMSISDNChangeRequest
	* @property {Number}  SPID   SPID is the service provider ID that the product belongs to
	* @property {String}  UUID   Limit search to a particular product instance
	* @property {String}  MSISDN MSISDN you want to change to
	*
	* @typedef  {Object}  MSISDNChangeResponse
	* @property {Number}  SPID 
	* @property {String}  UUID 
	*
	*/
	ChangeMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/msisdn_change`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ChangeMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ChangeMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ChangeMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ChangeMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ChangeMSISDN'))
			})
	},

	/**
	* RPC Method: DeprovisionSIM
	* Description: De-provision a SIM on the network - If there is a ported number, the ported number will be released back to IPMS, and a new number will be allocated to the SIM. Removes number off the SIM record
	* HTTP Method: POST
	* API Path: /api/v3/deprovision_sim
	* @param    {SIMDeprovisionRequest} req The API payload object (SIMDeprovisionRequest)
	* @return   {SIMDeprovisionResponse} The API response object (SIMDeprovisionResponse)
	*
	* @typedef  {Object}  SIMDeprovisionRequest
	* @property {Number}  SPID                
	* @property {String}  IMSI                IMSI of the provisioned SIM
	* @property {String}  SIMID               SIMID of the provisioned SIM
	* @property {String}  ICCID               ICCID of the provisioned SIM
	* @property {String}  MSISDN              MSISDN of the provisioned SIM
	* @property {String}  ProductInstanceUUID Product UUID
	*
	* @typedef  {Object}  SIMDeprovisionResponse
	* @property {Number}  SPID   
	* @property {String}  IMSI   
	* @property {String}  SIMID  
	* @property {String}  ICCID  
	* @property {String}  MSISDN Optional new MSISDN allocated as part of deprovisioning process
	*
	*/
	DeprovisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/deprovision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeprovisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeprovisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeprovisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeprovisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeprovisionSIM'))
			})
	},

	/**
	* RPC Method: GetDataSettings
	* Description: ## GetDataSettings
	* 
	* Get Data Settings of the product
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* 
	* ### Example Request
	* 
	* {
	* /api/v1/1/mobile_product/1bcdb203-ec7e-461c-8870-d8467a167a78/data_settings
	* }
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings
	* @param    {MobileGetDataSettingsRequest} req The API payload object (MobileGetDataSettingsRequest)
	* @return   {MobileGetDataSettingsResponse} The API response object (MobileGetDataSettingsResponse)
	*
	* @typedef  {Object}  MobileGetDataSettingsRequest
	* @property {Number}  SPID 
	* @property {String}  UUID ProductInstanceUUID
	*
	* @typedef  {Object}  MobileGetDataSettingsResponse
	* @property {DataEnabled} DataEnabled 
	*
	*/
	GetDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/mobile_product/${paramUUID}/data_settings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetDataSettings'))
			})
	},

	/**
	* RPC Method: GetMobileProductByUUID
	* Description: Get a single MobileProduct object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/mobile_product/${req.UUID}
	* @param    {MobileProductUUIDSearch} req The API payload object (MobileProductUUIDSearch)
	* @return   {MobileProduct} The API response object (MobileProduct)
	*
	* @typedef  {Object}  MobileProductUUIDSearch
	* @property {String}  UUID        
	* @property {Number}  SPID        Service Provider ID - always required
	* @property {String}  Description (optional) Description
	*
	* @typedef  {Object}  MobileProduct
	* @property {String}  UUID           
	* @property {Number}  SPID           Default Service Provider ID
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {ENUM}    ServiceType    ServiceType
	* @property {String}  CustomerUUID   
	*
	*/
	GetMobileProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/mobile_product/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			// Ensure we save the response in cache
			commit('mutSaveMobileProduct', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductByUUID'))
			})
	},

	/**
	* RPC Method: GetMobileProductsPaginated
	* Description: Searches for multiple MobileProduct objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/mobile_product
	* @param    {MobileProductSearchRequest} req The API payload object (MobileProductSearchRequest)
	* @return   {MobileProductPageResponse} The API response object (MobileProductPageResponse)
	*
	* @typedef  {Object}  MobileProductSearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	* @property {Number}  SPID           (optional) SPID
	* @property {String}  Description    (optional) Description
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  MobileProductPageResponse
	* @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {MobileProduct[]} MobileProducts 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetMobileProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/mobile_product`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			commit('mutSaveMobileProducts', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetMobileProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetMobileProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetMobileProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetMobileProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetMobileProductsPaginated'))
			})
	},

	/**
	* RPC Method: GetVoiceSettings
	* Description: ## GetVoiceSettings
	* 
	* Get voice settings of the product
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |UUID|string|The product instance UUID|
	* 
	* ### Example Request
	* 
	* {
	* /api/v1/1/mobile_product/1bcdb203-ec7e-461c-8870-d8467a167a78/voicesettings
	* }
	* 
	* HTTP Method: GET
	* API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings
	* @param    {MobileGetVoiceSettingsRequest} req The API payload object (MobileGetVoiceSettingsRequest)
	* @return   {MobileGetVoiceSettingsResponse} The API response object (MobileGetVoiceSettingsResponse)
	*
	* @typedef  {Object}  MobileGetVoiceSettingsRequest
	* @property {Number}  SPID 
	* @property {String}  UUID ProductInstanceUUID
	*
	* @typedef  {Object}  MobileGetVoiceSettingsResponse
	* @property {VoiceSettings[]} VoiceSettings 
	*
	*/
	GetVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v1/${paramSPID}/mobile_product/${paramUUID}/voicesettings`, { params: req }).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetVoiceSettings'))
			})
	},

	/**
	* RPC Method: ProvisionSIM
	* Description: Provision a SIM on the network
	* HTTP Method: POST
	* API Path: /api/v3/provision_sim
	* @param    {SIMProvisionRequest} req The API payload object (SIMProvisionRequest)
	* @return   {SIMProvisionResponse} The API response object (SIMProvisionResponse)
	*
	* @typedef  {Object}  SIMProvisionRequest
	* @property {Number}  SPID                           
	* @property {String}  IMSI                           Option 1: Permanent hidden
	* @property {String}  SIMID                          Option 3: Temporary showed
	* @property {String}  ICCID                          
	* @property {String}  MSISDN                         MSISDN is required
	* @property {String}  ProductCatalogAvailabilityUUID 
	*
	* @typedef  {Object}  SIMProvisionResponse
	* @property {Number}  SPID                           
	* @property {String}  IMSI                           IMSI of the provisioned SIM
	* @property {String}  SIMID                          SIMID of the provisioned SIM
	* @property {String}  ICCID                          ICCID of the provisioned SIM
	* @property {String}  MSISDN                         MSISDN of the provisioned SIM
	* @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID used to activate
	* @property {String}  ProductInstanceUUID            Product UUID
	*
	*/
	ProvisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/provision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ProvisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ProvisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ProvisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ProvisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ProvisionSIM'))
			})
	},

	/**
	* RPC Method: ReprovisionSIM
	* Description: Re-provision a SIM back, including deprovisioning of all sub-service. Internally calls DeprovisionSIM and then ProvisionSIM
	* HTTP Method: POST
	* API Path: /api/v3/reprovision_sim
	* @param    {SIMProvisionRequest} req The API payload object (SIMProvisionRequest)
	* @return   {SIMProvisionResponse} The API response object (SIMProvisionResponse)
	*
	* @typedef  {Object}  SIMProvisionRequest
	* @property {Number}  SPID                           
	* @property {String}  IMSI                           Option 1: Permanent hidden
	* @property {String}  SIMID                          Option 3: Temporary showed
	* @property {String}  ICCID                          
	* @property {String}  MSISDN                         MSISDN is required
	* @property {String}  ProductCatalogAvailabilityUUID 
	*
	* @typedef  {Object}  SIMProvisionResponse
	* @property {Number}  SPID                           
	* @property {String}  IMSI                           IMSI of the provisioned SIM
	* @property {String}  SIMID                          SIMID of the provisioned SIM
	* @property {String}  ICCID                          ICCID of the provisioned SIM
	* @property {String}  MSISDN                         MSISDN of the provisioned SIM
	* @property {String}  ProductCatalogAvailabilityUUID ProductCatalogAvailabilityUUID used to activate
	* @property {String}  ProductInstanceUUID            Product UUID
	*
	*/
	ReprovisionSIM({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/reprovision_sim`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ReprovisionSIM ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ReprovisionSIM ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ReprovisionSIM ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ReprovisionSIM UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ReprovisionSIM'))
			})
	},

	/**
	* RPC Method: UpdateDataSettings
	* Description: ## UpdateDataSettings
	* 
	* Update Data Settings of the product
	* All DataEnabled items need to be set at the same time
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |DataEnabled|object(DataEnabled)|The DataEnabled to be set|
	* 
	* ### Example Request
	* 
	*     {
	*        "SPID": 1,
	*        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*     	"DataEnabled": {
	*            "OCSChargeableDataRGBarred": false,
	*        }
	*     }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings
	* @param    {MobileUpdateDataSettingsRequest} req The API payload object (MobileUpdateDataSettingsRequest)
	* @return   {MobileUpdateDataSettingsResponse} The API response object (MobileUpdateDataSettingsResponse)
	*
	* @typedef  {Object}  MobileUpdateDataSettingsRequest
	* @property {Number}  SPID        
	* @property {String}  UUID        
	* @property {DataEnabled} DataEnabled 
	*
	* @typedef  {Object}  MobileUpdateDataSettingsResponse
	* @property {DataEnabled} DataEnabled 
	*
	*/
	UpdateDataSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/data_settings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateDataSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateDataSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateDataSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateDataSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateDataSettings'))
			})
	},

	/**
	* RPC Method: UpdateVoiceSettings
	* Description: ## UpdateVoiceSettings
	* 
	* Update voice settings of the product.
	* All VoiceSettings items need to be set at the same time.
	* 
	* ### Request fields
	* |**Field**|**Type**|**Description**|
	* |--|--|--|
	* |VoiceSettings|object(VoiceSettings)|The voice settings to be set|
	* 
	* ### Example Request
	* 
	*     {
	*        "SPID": 1,
	*        "UUID": "1bcdb203-ec7e-461c-8870-d8467a167a78",
	*     	"VoiceSettings": {
	*          "OutgoingCallerID": 3,
	*          "IncomingCallerID": true,
	*          "AllowConferencing": true,
	*          "CallWaitingAvailable": true,
	*          "CallWaitingEnabled": true,
	* 	        "CFBActive":false,
	* 	        "CFBAvailable":true,
	* 	        "CFBDestination":"CFBDestination stub",
	* 	        "CFNRCActive":false,
	* 	        "CFNRCAvailable":true,
	* 	        "CFNRCDestination":"CFNRCDestination stub",
	* 	        "CFNRYAvailable":true,
	* 	        "CFNRYActive":false,
	* 	        "CFNRYDestination":true,
	* 	        "CFNRYRingtime":30,
	* 	        "CFUActive":false,
	* 	        "CFUAvailable":true,
	* 	        "CFUDestination":"CFUDestination stub",
	* 	        "BarIncomingCalls":false,
	* 	        "BarOutgoingCalls":false,
	* 	        "AllowIncomingCalls":true,
	* 	        "AllowOutgoingCalls":true,
	* 	        "AllowSMSSend":true,
	* 	        "AllowSMSRecieve":true,
	* 	        "OCSBlockPremiumCalls":false,
	* 	        "OCSBlockPremiumSMS":false,
	* 	        "OCSOutgoingIDDBarred":false,
	* 	        "OCSOutgoingTextBarred":false,
	* 	        "OCSRoamingBarred":false,
	* 	        "OCSOutCallsWhileRoamingBarred":false,
	* 	        "OCSInCallsWhileRoamingBarred":false,
	* 	        "OCSOOBWhileRoamingBarred":false,
	*        }
	*     }
	* 
	* HTTP Method: PUT
	* API Path: /api/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings
	* @param    {MobileUpdateVoiceSettingsRequest} req The API payload object (MobileUpdateVoiceSettingsRequest)
	* @return   {MobileUpdateVoiceSettingsResponse} The API response object (MobileUpdateVoiceSettingsResponse)
	*
	* @typedef  {Object}  MobileUpdateVoiceSettingsRequest
	* @property {Number}  SPID          Service Provider ID
	* @property {String}  UUID          UUID
	* @property {VoiceSettings[]} VoiceSettings 
	*
	* @typedef  {Object}  MobileUpdateVoiceSettingsResponse
	* @property {VoiceSettings[]} VoiceSettings 
	*
	*/
	UpdateVoiceSettings({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v1/${req.SPID}/mobile_product/${req.UUID}/voicesettings`, req).then(response => {
			// Success response
			if (!response.data) { return Promise.reject(Error('no response data')) }

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateVoiceSettings ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateVoiceSettings ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateVoiceSettings ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateVoiceSettings UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateVoiceSettings'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};