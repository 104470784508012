import { extend } from 'vee-validate';

function price() {
    extend("price", {
        validate(value) {
            const regex = new RegExp(/^(\d+(?:[.]\d{1,2})?)$/);
            return regex.test(value);
        },
        message: field => `The ${field} must contain numbers separated by '.'`,
    })
}

export default price