import { errMessage } from '@/helpers/errMessage';

export default function errMessageApi(error, default_message = 'unknown error') {
    
    // return (error && error.response && error.response.data)
    //     ? (error.response.data.err_message || error.response.data.message || default_message)
    //     : (error ? error.err_message || error.message || default_message : default_message)

    // const messageText = (error && error.response && error.response.data && error.response.data.description)
    // ? (error.response.data.err_message || error.response.data.message || error.response.data.description || default_message)
    // : (error ? error.err_message || error.message || default_message : default_message)

    // todo добавить оброботку ошибки с zendesk api

    const messageText = (error && error.response && error.response.data)
        ? (error.response.data.err_message || error.response.data.message || error.response.data.err_field || default_message)
        : (error ? error.err_message || error.message || default_message : default_message)

    errMessage(messageText);
}
