import axios from 'axios';

const TICKETS_URL = `${process.env.VUE_APP_API_ZENDESK_HOST}/tickets`;
const TICKETS_SEARCH_URL = `${process.env.VUE_APP_API_ZENDESK_HOST}/search`;

const state = {
    tickets: {},
    count: 0,
    comments: []
};

const getters = {
    ticketByID(state){
        return id => {
            return state.tickets[id];
        }
    },
    count(state) {
        return state.count
    },
    comments(state) {
        return state.comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
};

const mutations = {
    mutTicketsList(state, tickets){
        for (let i = 0, len = tickets.length; i < len; i++) {
            const ticket = tickets[i];
            const id = ticket.id;
            state.tickets[id] = ticket;
        }
    },

    mutTicketsCount(state, count) {
        state.count = count.value;
    },

    // mutTicketById(state, ticket) {
    //     const id = ticket.id;
    //     state.tickets[ id ] = ticket;
    // },

    mutResetTickets(state) {
        state.tickets = {};
    },

    mutCommentsList(state, comments){
        // state.comments = comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        state.comments = comments;
    },

    mutAddComment(state, comment) {
        state.comments.push(comment);
    }
};

const actions = {
    getTickets({ commit }, params) {
        return axios.get(TICKETS_URL, { params, isSPID: true }).then(({apidata}) => {
            commit('mutTicketsList', apidata);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getSearchedTickets({ commit }, params) {
        return axios.get(TICKETS_SEARCH_URL, { params, isSPID: true }).then(({apidata}) => {
            commit('mutTicketsList', apidata.results);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    getTicketsCount({ commit }) {
        return axios.get(`${TICKETS_URL}/count`).then(({apidata}) => {
            commit('mutTicketsCount', apidata.count);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    // getTicketById({ getters, commit }, { id, params }){
    getTicketById({ }, { id, params }){
        return axios.get(`${TICKETS_URL}/${id}`, { params }).then(({apidata}) => {
            // commit('mutTicketById', apidata);
            // Возвращаем геттер
            // return Promise.resolve( getters.ticketByID(id) );
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error))
    },

    getCommentsByTicketId({ commit, getter }, id){
        return axios.get(`${TICKETS_URL}/${id}/comments`).then(({apidata}) => {
            commit('mutCommentsList', apidata.comments)
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },

    setCommentByTicketId({ commit }, ticket){
        return axios.put(`${TICKETS_URL}/${ticket.id}`, { ticket }).then(({apidata}) => {
            const comment = apidata.audit.events[0];
            comment.created_at = apidata.audit.created_at;
            commit('mutAddComment', comment);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}