// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: customer.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	Customers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	CustomerSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getCustomerByUUID: state => (UUID) => (state.Customers.UUIDs[ UUID ] && !state.Customers.UUIDs[ UUID ].expired()) ?  state.Customers.UUIDs[ UUID ].hashedData() : null,
	getCustomersForSearchText: state => (SearchText) => state.CustomerSearches[ SearchText ] ? state.CustomerSearches[ SearchText ] : null,
};

const mutations = {
	// mutSaveCustomer saves a single Customer object
	// into both the StoreHash cache and the VueX store
	mutSaveCustomer(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.Customers.UUIDs)) {
			state.Customers.UUIDs[ obj.UUID ] = new StoreHash(state.Customers.lifetime)
		}
		state.Customers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveCustomerByUUID removes a Customer object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveCustomerByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.Customers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveCustomerSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveCustomerSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.Customers)) {
			for (let i = 0; i < Response.Customers.length; i++) {
				storedObj.UUIDs.push(Response.Customers[i].UUID)
			}
		}
		state.CustomerSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.CustomerSearches.SearchTexts)) {
			state.CustomerSearches.SearchTexts[ SearchText ] = new StoreHash(state.CustomerSearches.lifetime)
		}
		state.CustomerSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveCustomerSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveCustomerSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.CustomerSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.CustomerSearches[ SearchText ]               // remove item from VueX state
		delete state.CustomerSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSaveCustomers saves a paginated response of Customers
	mutSaveCustomers(state, { Response }) {
		if (!Response || !Array.isArray(Response.Customers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.Customers.length; i++) {
			if (!(Response.Customers[i].UUID in state.Customers.UUIDs)) {
				state.Customers.UUIDs[ Response.Customers[i].UUID ] = new StoreHash(state.Customers.lifetime)
			}
			state.Customers.UUIDs[ Response.Customers[i].UUID ].fix(Response.Customers[i])  // Add single Customer to StoreHash
		}
	},
};

const actions = {
	// getCachedCustomerByUUID fetches a Customer from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomerByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.Customers.UUIDs[ UUID ] && !state.Customers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.Customers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetCustomerByUUID', { UUID }).then(respPayload => {
			commit('mutSaveCustomer', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedCustomersBySearch fetches Customers from the cache
	// and if not in the cache, fetches it from the API
	getCachedCustomersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.CustomerSearches.SearchTexts[ SearchText ] && !state.CustomerSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getCustomersForSearchText(SearchText)
			resp.Customers = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.Customers.push(getters.getCustomerByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('FindCustomersPaginated', apiReq).then(respPayload => {
			commit('mutSaveCustomerSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveCustomerSearch', SearchText)
			return Promise.reject(error)
		})
	},

	//
	// Service: CustomerRPC
	// 
	/**
	* AddCustomer: Add a new Customer record
	* HTTP Method: POST
	* API Path: /api/v2/${req.SPID}/customer
	* @param    {Customer} req The API payload object (Customer)
	* @return   {Customer} The API response object (Customer)
	*
	* @typedef  {Object}  Customer
	* @property {String}  UUID                The Unique ID (UUIDv4)
	* @property {Boolean} Active              Whether or not this Customer is active or not
	* @property {Number}  SPID                The Service Provider ID
	* @property {String}  FirstName           First Name
	* @property {String}  LastName            Last Name
	* @property {String}  Language            Language (default: en)
	* @property {String}  Email               Email address
	* @property {String}  MobileNumber        Mobile phone number
	* @property {Number}  CreatedAt           When the record was first created
	* @property {Number}  UpdatedAt           When the record was last updated
	* @property {Number}  DeletedAt           When the record was deleted
	* @property {ENUM}    NotifyBilling       How to notify the customer of Billing issues
	* @property {ENUM}    NotifySpecialOffers How to send Special Offers / Marketing info
	* @property {Boolean} MobileVerified      Is the mobile number verified?
	* @property {Boolean} EmailVerified       Is the Email address verifided?
	* @property {Number}  BillingDOM          Billing Day Of Month
	* @property {ENUM}    EarlyAccess         Enrolled in the EarlyAccess programme?
	* @property {Boolean} IsMigrating         Is the customer being migrated?
	* @property {String}  ExternalRef1        Clarify account ID
	* @property {String}  ExternalRef2        TP2 account ID
	* @property {String}  ExternalRef3        C3 / Siebel account ID
	* @property {String}  ExternalRef4        reserved / unused
	* @property {String}  ExternalRef5        reserved / unused
	* @property {String}  MigrationData       Migration data
	*
	*/
	AddCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v2/${req.SPID}/customer`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddCustomer'))
			})
	},

	/**
	* DeleteCustomer: delete customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	* @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	* @return   {Customer} The API response object (Customer)
	*
	* @typedef  {Object}  CustomerUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The Customer UUID to search for
	*
	* @typedef  {Object}  Customer
	* @property {String}  UUID                The Unique ID (UUIDv4)
	* @property {Boolean} Active              Whether or not this Customer is active or not
	* @property {Number}  SPID                The Service Provider ID
	* @property {String}  FirstName           First Name
	* @property {String}  LastName            Last Name
	* @property {String}  Language            Language (default: en)
	* @property {String}  Email               Email address
	* @property {String}  MobileNumber        Mobile phone number
	* @property {Number}  CreatedAt           When the record was first created
	* @property {Number}  UpdatedAt           When the record was last updated
	* @property {Number}  DeletedAt           When the record was deleted
	* @property {ENUM}    NotifyBilling       How to notify the customer of Billing issues
	* @property {ENUM}    NotifySpecialOffers How to send Special Offers / Marketing info
	* @property {Boolean} MobileVerified      Is the mobile number verified?
	* @property {Boolean} EmailVerified       Is the Email address verifided?
	* @property {Number}  BillingDOM          Billing Day Of Month
	* @property {ENUM}    EarlyAccess         Enrolled in the EarlyAccess programme?
	* @property {Boolean} IsMigrating         Is the customer being migrated?
	* @property {String}  ExternalRef1        Clarify account ID
	* @property {String}  ExternalRef2        TP2 account ID
	* @property {String}  ExternalRef3        C3 / Siebel account ID
	* @property {String}  ExternalRef4        reserved / unused
	* @property {String}  ExternalRef5        reserved / unused
	* @property {String}  MigrationData       Migration data
	*
	*/
	DeleteCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v2/${req.SPID}/customer/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteCustomer'))
			})
	},

	/**
	* FindCustomersPaginated: Search for Customers by given fields
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer
	* @param    {CustomerSearchRequest} req The API payload object (CustomerSearchRequest)
	* @return   {CustomerPageResponse} The API response object (CustomerPageResponse)
	*
	* @typedef  {Object}  CustomerSearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {String}  UUID            The Customer UUID to search/filter by
	* @property {String}  Email           Email address to search/filter by
	* @property {String}  MobileNumber    Mobile phone number to search/filter by
	* @property {Number}  SPID            Service Provider to search/filter by
	* @property {Boolean} IncludeInactive Include inactive records/
	* @property {String}  FirstName       First Name to search/filter by
	* @property {String}  LastName        Last Name to search/filter by
	* @property {String}  SmartSearch     Search using multiple fields at once
	* @property {String}  ExternalRef1    External Reference 1 to search/filter by
	* @property {String}  ExternalRef2    External Reference 2 to search/filter by
	* @property {String}  ExternalRef3    External Reference 3 to search/filter by
	* @property {String}  ExternalRef4    External Reference 4 to search/filter by
	* @property {String}  ExternalRef5    External Reference 5 to search/filter by
	* @property {String}  UUIDs           Search for multiple UUIDs at once (takes precendence over all other search parameters)
	* @property {Boolean} IsMigrating     Search for migrated customers only
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  CustomerPageResponse
	* @property {PageInfo} PageInfo  PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {Customer[]} Customers The list of matching Customers
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	FindCustomersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v2/${req.SPID}/customer`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveCustomers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindCustomersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindCustomersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindCustomersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindCustomersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindCustomersPaginated'))
			})
	},

	/**
	* GetCustomerByUUID: Get a Customer using the unique CustomerUUID
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	* @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	* @return   {Customer} The API response object (Customer)
	*
	* @typedef  {Object}  CustomerUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The Customer UUID to search for
	*
	* @typedef  {Object}  Customer
	* @property {String}  UUID                The Unique ID (UUIDv4)
	* @property {Boolean} Active              Whether or not this Customer is active or not
	* @property {Number}  SPID                The Service Provider ID
	* @property {String}  FirstName           First Name
	* @property {String}  LastName            Last Name
	* @property {String}  Language            Language (default: en)
	* @property {String}  Email               Email address
	* @property {String}  MobileNumber        Mobile phone number
	* @property {Number}  CreatedAt           When the record was first created
	* @property {Number}  UpdatedAt           When the record was last updated
	* @property {Number}  DeletedAt           When the record was deleted
	* @property {ENUM}    NotifyBilling       How to notify the customer of Billing issues
	* @property {ENUM}    NotifySpecialOffers How to send Special Offers / Marketing info
	* @property {Boolean} MobileVerified      Is the mobile number verified?
	* @property {Boolean} EmailVerified       Is the Email address verifided?
	* @property {Number}  BillingDOM          Billing Day Of Month
	* @property {ENUM}    EarlyAccess         Enrolled in the EarlyAccess programme?
	* @property {Boolean} IsMigrating         Is the customer being migrated?
	* @property {String}  ExternalRef1        Clarify account ID
	* @property {String}  ExternalRef2        TP2 account ID
	* @property {String}  ExternalRef3        C3 / Siebel account ID
	* @property {String}  ExternalRef4        reserved / unused
	* @property {String}  ExternalRef5        reserved / unused
	* @property {String}  MigrationData       Migration data
	*
	*/
	GetCustomerByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		return axios.get(`/v2/${req.SPID}/customer/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomerByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomerByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomerByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomerByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomerByUUID'))
			})
	},

	/**
	* GetCustomersUUIDs: 
	* HTTP Method: GET
	* API Path: /api/v2/${req.SPID}/customer_uuids
	* @param    {CustomerSearchRequest} req The API payload object (CustomerSearchRequest)
	* @return   {tktcommonproto.UUIDsResponse} The API response object (tktcommonproto.UUIDsResponse)
	*
	* @typedef  {Object}  CustomerSearchRequest
	* @property {SearchOptions[]} SearchOptions   Search options to use (for sorting and/or pagination)
	* @property {String}  UUID            The Customer UUID to search/filter by
	* @property {String}  Email           Email address to search/filter by
	* @property {String}  MobileNumber    Mobile phone number to search/filter by
	* @property {Number}  SPID            Service Provider to search/filter by
	* @property {Boolean} IncludeInactive Include inactive records/
	* @property {String}  FirstName       First Name to search/filter by
	* @property {String}  LastName        Last Name to search/filter by
	* @property {String}  SmartSearch     Search using multiple fields at once
	* @property {String}  ExternalRef1    External Reference 1 to search/filter by
	* @property {String}  ExternalRef2    External Reference 2 to search/filter by
	* @property {String}  ExternalRef3    External Reference 3 to search/filter by
	* @property {String}  ExternalRef4    External Reference 4 to search/filter by
	* @property {String}  ExternalRef5    External Reference 5 to search/filter by
	* @property {String}  UUIDs           Search for multiple UUIDs at once (takes precendence over all other search parameters)
	* @property {Boolean} IsMigrating     Search for migrated customers only
	*
	* @typedef  {Object}  tktcommonproto.UUIDsResponse
	*
	*/
	GetCustomersUUIDs({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: FindUUIDs)
		return axios.get(`/v2/${req.SPID}/customer_uuids`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCustomersUUIDs ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCustomersUUIDs ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCustomersUUIDs ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCustomersUUIDs UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCustomersUUIDs'))
			})
	},

	/**
	* PurgeCustomer: Purge customer entity via customer UUID
	* HTTP Method: DELETE
	* API Path: /api/v2/${req.SPID}/customer/${req.UUID}/purge
	* @param    {CustomerUUIDSearch} req The API payload object (CustomerUUIDSearch)
	* @return   {Customer} The API response object (Customer)
	*
	* @typedef  {Object}  CustomerUUIDSearch
	* @property {Number}  SPID Service Provider ID
	* @property {String}  UUID The Customer UUID to search for
	*
	* @typedef  {Object}  Customer
	* @property {String}  UUID                The Unique ID (UUIDv4)
	* @property {Boolean} Active              Whether or not this Customer is active or not
	* @property {Number}  SPID                The Service Provider ID
	* @property {String}  FirstName           First Name
	* @property {String}  LastName            Last Name
	* @property {String}  Language            Language (default: en)
	* @property {String}  Email               Email address
	* @property {String}  MobileNumber        Mobile phone number
	* @property {Number}  CreatedAt           When the record was first created
	* @property {Number}  UpdatedAt           When the record was last updated
	* @property {Number}  DeletedAt           When the record was deleted
	* @property {ENUM}    NotifyBilling       How to notify the customer of Billing issues
	* @property {ENUM}    NotifySpecialOffers How to send Special Offers / Marketing info
	* @property {Boolean} MobileVerified      Is the mobile number verified?
	* @property {Boolean} EmailVerified       Is the Email address verifided?
	* @property {Number}  BillingDOM          Billing Day Of Month
	* @property {ENUM}    EarlyAccess         Enrolled in the EarlyAccess programme?
	* @property {Boolean} IsMigrating         Is the customer being migrated?
	* @property {String}  ExternalRef1        Clarify account ID
	* @property {String}  ExternalRef2        TP2 account ID
	* @property {String}  ExternalRef3        C3 / Siebel account ID
	* @property {String}  ExternalRef4        reserved / unused
	* @property {String}  ExternalRef5        reserved / unused
	* @property {String}  MigrationData       Migration data
	*
	*/
	PurgeCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v2/${req.SPID}/customer/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeCustomer'))
			})
	},

	/**
	* UpdateCustomer: Update a Customer record
	* HTTP Method: PUT
	* API Path: /api/v2/${req.SPID}/customer/${req.UUID}
	* @param    {Customer} req The API payload object (Customer)
	* @return   {Customer} The API response object (Customer)
	*
	* @typedef  {Object}  Customer
	* @property {String}  UUID                The Unique ID (UUIDv4)
	* @property {Boolean} Active              Whether or not this Customer is active or not
	* @property {Number}  SPID                The Service Provider ID
	* @property {String}  FirstName           First Name
	* @property {String}  LastName            Last Name
	* @property {String}  Language            Language (default: en)
	* @property {String}  Email               Email address
	* @property {String}  MobileNumber        Mobile phone number
	* @property {Number}  CreatedAt           When the record was first created
	* @property {Number}  UpdatedAt           When the record was last updated
	* @property {Number}  DeletedAt           When the record was deleted
	* @property {ENUM}    NotifyBilling       How to notify the customer of Billing issues
	* @property {ENUM}    NotifySpecialOffers How to send Special Offers / Marketing info
	* @property {Boolean} MobileVerified      Is the mobile number verified?
	* @property {Boolean} EmailVerified       Is the Email address verifided?
	* @property {Number}  BillingDOM          Billing Day Of Month
	* @property {ENUM}    EarlyAccess         Enrolled in the EarlyAccess programme?
	* @property {Boolean} IsMigrating         Is the customer being migrated?
	* @property {String}  ExternalRef1        Clarify account ID
	* @property {String}  ExternalRef2        TP2 account ID
	* @property {String}  ExternalRef3        C3 / Siebel account ID
	* @property {String}  ExternalRef4        reserved / unused
	* @property {String}  ExternalRef5        reserved / unused
	* @property {String}  MigrationData       Migration data
	*
	*/
	UpdateCustomer({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v2/${req.SPID}/customer/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveCustomer', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateCustomer ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateCustomer ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateCustomer ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateCustomer UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateCustomer'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};