// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: prepay.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	PrepayBalances: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getPrepayBalanceByUUID: state => (UUID) => (state.PrepayBalances.UUIDs[ UUID ] && !state.PrepayBalances.UUIDs[ UUID ].expired()) ?  state.PrepayBalances.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSavePrepayBalance saves a single PrepayBalance object
	// into both the StoreHash cache and the VueX store
	mutSavePrepayBalance(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PrepayBalances.UUIDs)) {
			state.PrepayBalances.UUIDs[ obj.UUID ] = new StoreHash(state.PrepayBalances.lifetime)
		}
		state.PrepayBalances.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePrepayBalanceByUUID removes a PrepayBalance object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePrepayBalanceByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PrepayBalances.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedPrepayBalanceByUUID fetches a PrepayBalance from the cache
	// and if not in the cache, fetches it from the API
	getCachedPrepayBalanceByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PrepayBalances.UUIDs[ UUID ] && !state.PrepayBalances.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PrepayBalances.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPrepayBalanceByUUID', { UUID }).then(respPayload => {
			commit('mutSavePrepayBalance', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePrepayBalanceByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: MobilePrepayRPC
	// 
	/**
	 * RPC Method: ActivatePrepayAddon
	 * Description: Applies an AddOn to a PrepayProduct
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.PrepayProductUUID}/addon/${req.AddonAvailabilityUUID}
	 * @param    {PrepayProductAddonRequest} req The API payload object (PrepayProductAddonRequest)
	 * @return   {PrepayProductCommonRequestResponse} The API response object (PrepayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  PrepayProductAddonRequest
	 * @property {Number}  SPID                  SPID
	 * @property {String}  PrepayProductUUID     PrepayProductUUID
	 * @property {String}  AddonAvailabilityUUID AddonAvailabilityUUID
	 *
	 * @typedef  {Object}  PrepayProductCommonRequestResponse
	 * @property {PrepayProduct} PrepayProduct      Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PrepayAddon} PrepayAddon
	 *
	 */
	ActivatePrepayAddon({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/mobileprepay/${req.PrepayProductUUID}/addon/${req.AddonAvailabilityUUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ActivatePrepayAddon ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ActivatePrepayAddon ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ActivatePrepayAddon ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ActivatePrepayAddon UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ActivatePrepayAddon'))
			})
	},

	/**
	 * RPC Method: ActivateVoucher
	 * Description: Applies an Voucher to a PrepayProduct
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}/apply_voucher
	 * @param    {ActivateVoucherRequest} req The API payload object (ActivateVoucherRequest)
	 * @return   {PrepayProductCommonRequestResponse} The API response object (PrepayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  ActivateVoucherRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID
	 * @property {String}  VoucherCode
	 *
	 * @typedef  {Object}  PrepayProductCommonRequestResponse
	 * @property {PrepayProduct} PrepayProduct      Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PrepayAddon} PrepayAddon
	 *
	 */
	ActivateVoucher({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/mobileprepay/${req.UUID}/apply_voucher`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ActivateVoucher ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ActivateVoucher ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ActivateVoucher ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ActivateVoucher UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ActivateVoucher'))
			})
	},

	/**
	 * RPC Method: BeginActivationPrepayProduct
	 * Description: Begin activating a Prepay Product
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/begin_activation
	 * @param    {PrepayProductActivationRequest} req The API payload object (PrepayProductActivationRequest)
	 * @return   {PrepayProductActivationResponse} The API response object (PrepayProductActivationResponse)
	 *
	 * @typedef  {Object}  PrepayProductActivationRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         UUID of PrepayProduct
	 * @property {String}  CustomerUUID string Token = 7;
	 *
	 * @typedef  {Object}  PrepayProductActivationResponse
	 * @property {Number}  SPID
	 * @property {String}  UUID UUID of PrepayProduct
	 *
	 */
	BeginActivationPrepayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/begin_activation`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('BeginActivationPrepayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('BeginActivationPrepayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('BeginActivationPrepayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('BeginActivationPrepayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in BeginActivationPrepayProduct'))
			})
	},

	/**
	 * RPC Method: DeletePrepayProductByUUID
	 * Description: Terminates the Prepay Product
	 * HTTP Method: DELETE
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}
	 * @param    {PrepayProductUUIDSearch} req The API payload object (PrepayProductUUIDSearch)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProductUUIDSearch
	 * @property {String}  UUID
	 * @property {Number}  SPID Service Provider ID - always required
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	DeletePrepayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/mobileprepay/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePrepayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePrepayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePrepayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePrepayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePrepayProductByUUID'))
			})
	},

	/**
	 * RPC Method: FinaliseActivationPrepayProduct
	 * Description: Complete activation of a Prepay Product
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/finalise_activation
	 * @param    {PrepayProductActivationRequest} req The API payload object (PrepayProductActivationRequest)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProductActivationRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         UUID of PrepayProduct
	 * @property {String}  CustomerUUID string Token = 7;
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	FinaliseActivationPrepayProduct({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/finalise_activation`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FinaliseActivationPrepayProduct ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FinaliseActivationPrepayProduct ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FinaliseActivationPrepayProduct ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FinaliseActivationPrepayProduct UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FinaliseActivationPrepayProduct'))
			})
	},

	/**
	 * RPC Method: GetAvailablePrepayAddons
	 * Description: Gets available prepay addons using a search request
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/prepay_mobile_addons
	 * @param    {PrepayAddonSearchRequest} req The API payload object (PrepayAddonSearchRequest)
	 * @return   {PrepayAddonPageResponse} The API response object (PrepayAddonPageResponse)
	 *
	 * @typedef  {Object}  PrepayAddonSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  UUID           (optional)
	 * @property {PrepayAddonSearchRequest_SearchFilterEntry[]} SearchFilter   SearchFilter
	 * @property {Boolean} IncludePlans   IncludePlans
	 * @property {Boolean} ExcludeAddons  ExcludeAddons
	 *
	 * @typedef  {Object}  PrepayAddonPageResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PrepayAddon[]} PrepayAddons
	 *
	 */
	GetAvailablePrepayAddons({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/prepay_mobile_addons`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetAvailablePrepayAddons ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetAvailablePrepayAddons ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetAvailablePrepayAddons ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetAvailablePrepayAddons UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetAvailablePrepayAddons'))
			})
	},

	/**
	 * RPC Method: GetAvailablePrepayAddonsPaginated
	 * Description: Searches for available addon objects using a search request
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/mobileprepay_addon
	 * @param    {PrepayAddonSearchRequest} req The API payload object (PrepayAddonSearchRequest)
	 * @return   {PrepayAddonPageResponse} The API response object (PrepayAddonPageResponse)
	 *
	 * @typedef  {Object}  PrepayAddonSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  UUID           (optional)
	 * @property {PrepayAddonSearchRequest_SearchFilterEntry[]} SearchFilter   SearchFilter
	 * @property {Boolean} IncludePlans   IncludePlans
	 * @property {Boolean} ExcludeAddons  ExcludeAddons
	 *
	 * @typedef  {Object}  PrepayAddonPageResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PrepayAddon[]} PrepayAddons
	 *
	 */
	GetAvailablePrepayAddonsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/mobileprepay_addon`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetAvailablePrepayAddonsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetAvailablePrepayAddonsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetAvailablePrepayAddonsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetAvailablePrepayAddonsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetAvailablePrepayAddonsPaginated'))
			})
	},

	/**
	 * RPC Method: GetAvailablePrepayPlans
	 * Description: Gets available prepay base plans using a search request
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/prepay_mobile_plans
	 * @param    {PrepayAddonSearchRequest} req The API payload object (PrepayAddonSearchRequest)
	 * @return   {PrepayAddonPageResponse} The API response object (PrepayAddonPageResponse)
	 *
	 * @typedef  {Object}  PrepayAddonSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  UUID           (optional)
	 * @property {PrepayAddonSearchRequest_SearchFilterEntry[]} SearchFilter   SearchFilter
	 * @property {Boolean} IncludePlans   IncludePlans
	 * @property {Boolean} ExcludeAddons  ExcludeAddons
	 *
	 * @typedef  {Object}  PrepayAddonPageResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PrepayAddon[]} PrepayAddons
	 *
	 */
	GetAvailablePrepayPlans({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/prepay_mobile_plans`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetAvailablePrepayPlans ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetAvailablePrepayPlans ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetAvailablePrepayPlans ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetAvailablePrepayPlans UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetAvailablePrepayPlans'))
			})
	},

	/**
	 * RPC Method: GetCurrentPrepayAddonsPaginated
	 * Description: Lists the current addons that are assocated to this product
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}/addons
	 * @param    {PrepayAddonSearchRequest} req The API payload object (PrepayAddonSearchRequest)
	 * @return   {PrepayAddonPageResponse} The API response object (PrepayAddonPageResponse)
	 *
	 * @typedef  {Object}  PrepayAddonSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  UUID           (optional)
	 * @property {PrepayAddonSearchRequest_SearchFilterEntry[]} SearchFilter   SearchFilter
	 * @property {Boolean} IncludePlans   IncludePlans
	 * @property {Boolean} ExcludeAddons  ExcludeAddons
	 *
	 * @typedef  {Object}  PrepayAddonPageResponse
	 * @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PrepayAddon[]} PrepayAddons
	 *
	 */
	GetCurrentPrepayAddonsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/mobileprepay/${paramUUID}/addons`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetCurrentPrepayAddonsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetCurrentPrepayAddonsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetCurrentPrepayAddonsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetCurrentPrepayAddonsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetCurrentPrepayAddonsPaginated'))
			})
	},

	/**
	 * RPC Method: GetPrepayProductByMSISDN
	 * Description: Get a single PrepayProduct object using the MSISDN
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/mobileprepaybymsisdn/${req.MSISDN}
	 * @param    {PrepayProduct} req The API payload object (PrepayProduct)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	GetPrepayProductByMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramMSISDN = encodeURIComponent(req.MSISDN)
		return axios.get(`/v3/${paramSPID}/mobileprepaybymsisdn/${paramMSISDN}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPrepayProductByMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPrepayProductByMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPrepayProductByMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPrepayProductByMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPrepayProductByMSISDN'))
			})
	},

	/**
	 * RPC Method: GetPrepayProductByUUID
	 * Description: Get a single PrepayProduct object using the UUID
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}
	 * @param    {PrepayProduct} req The API payload object (PrepayProduct)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	GetPrepayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		let paramCustomerUUID = encodeURIComponent(req.CustomerUUID)
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/${paramSPID}/customer/${paramCustomerUUID}/mobileprepay/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPrepayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPrepayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPrepayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPrepayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPrepayProductByUUID'))
			})
	},

	/**
	 * RPC Method: GetPrepayProductsPaginated
	 * Description: Searches for multiple PrepayProduct objects using a search request
	 * HTTP Method: GET
	 * API Path: /api/v3/${req.SPID}/mobileprepay
	 * @param    {PrepayProductSearchRequest} req The API payload object (PrepayProductSearchRequest)
	 * @return   {PrepayProductPageResponse} The API response object (PrepayProductPageResponse)
	 *
	 * @typedef  {Object}  PrepayProductSearchRequest
	 * @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	 * @property {Boolean} IncludeDeleted IncludeDeleted field present on the search request ensures that deleted records (records with DeleteAtNanos > 0) are not returned unless this is set
	 * @property {Number}  SPID           (optional) SPID
	 * @property {String}  Description    (optional) Description
	 * @property {String}  CustomerUUID   (optional) CustomerUUID
	 * @property {ENUM}    Status         (optional) Status
	 * @property {String}  MSISDN         (optional) MSISDN
	 * @property {ENUM}    PortingAction  (optional) PortingAction
	 * @property {String}  PortingMSISDN  (optional) PortingMSISDN
	 *
	 * @typedef  {Object}  PrepayProductPageResponse
	 * @property {PageInfo} PageInfo       PageInfo only needs the values ThisPageNumber && PageSize set
	 * @property {PrepayProduct[]} PrepayProducts
	 *
	 */
	GetPrepayProductsPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/mobileprepay`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPrepayProductsPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPrepayProductsPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPrepayProductsPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPrepayProductsPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPrepayProductsPaginated'))
			})
	},

	/**
	 * RPC Method: PrepayProductPortCancel
	 * Description: Cancels the IPMS Port Request if the port has not yet been completed. May only be called if we are the gaining service provider.
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/portcancel
	 * @param    {PrepayProductPortCancelRequest} req The API payload object (PrepayProductPortCancelRequest)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProductPortCancelRequest
	 * @property {Number}  SPID         Service Provider ID
	 * @property {String}  UUID         UUID of PrepayProduct
	 * @property {String}  CustomerUUID Unique identifier of the customer
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	PrepayProductPortCancel({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/portcancel`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PrepayProductPortCancel ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PrepayProductPortCancel ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PrepayProductPortCancel ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PrepayProductPortCancel UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PrepayProductPortCancel'))
			})
	},

	/**
	 * RPC Method: PrepayProductPortRequest
	 * Description: Places a Port Request with the tkt-nzlmnp-svc (calls ServiceProviderRequestPort). If successful, begins an automated process involving the IPMS, the gaining and losing service providers, and each respective carrier (if an inter-carrier port). The product service will be notified when the number is ready to be provisioned by the tkt-nzlmnp-svc. The caller may choose to omit the losing service provider ID and the NZLMNP service will attempt to determine it, however if unsuccessful it will return a list of known service provider for the Donor Carrier from which the caller may select from to resubmit the request.
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/portnumber
	 * @param    {PrepayProductNumberPortRequest} req The API payload object (PrepayProductNumberPortRequest)
	 * @return   {PrepayProductNumberPortResponse} The API response object (PrepayProductNumberPortResponse)
	 *
	 * @typedef  {Object}  PrepayProductNumberPortRequest
	 * @property {Number}  SPID          Service Provider ID
	 * @property {String}  UUID          UUID of PrepayProduct
	 * @property {String}  MSISDN        The phone number the customer wants to keep
	 * @property {String}  OldSIMID      The SIMID of the customer's current service (on current provider). Required if coming from Prepay.
	 * @property {String}  CustomerUUID
	 * @property {Number}  LSP           Losing Service Provider IPMS ID. If omitted/zero, LMNP will attempt to discover LSP itself by querying the IPMS. However, if unsuccessful will return an error.,  In the case where it is provided, the LSPOverride flag will be 'true' in the request to the IPMS. It will be 'false' if the LSP if discovered.
	 * @property {String}  AccountNumber The account number of the customer's current service (on current provider). Required if coming from OnAccount.
	 * @property {String}  CustomerName  The name on the customer's account at the current service (on current provider). If coming from OnAccount, LMNP will attempt to use first and last name from the customer service using the CustomerUUID. Ideally this should be provided to avoid mis-match.
	 *
	 * @typedef  {Object}  PrepayProductNumberPortResponse
	 * @property {Number}  SPID                      Service Provider ID
	 * @property {String}  UUID                      UUID of PrepayProduct
	 * @property {String}  MSISDN                    The SIMID to swap onto, the old SIM will be marked as ineligable
	 * @property {Number}  PortRFS                   When the port is expected to take place
	 * @property {Boolean} TwoFANotificationExpected Will the port require a 2FA response
	 * @property {ServiceProviderData[]} ServiceProviderList       List of service providers on the Donor Carrier, provided if the NZLMNP was unable to make the port request because the LSP could not be determined.
	 *
	 */
	PrepayProductPortRequest({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/portnumber`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PrepayProductPortRequest ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PrepayProductPortRequest ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PrepayProductPortRequest ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PrepayProductPortRequest UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PrepayProductPortRequest'))
			})
	},

	/**
	 * RPC Method: PrepayProductRoaming
	 * Description: Enable roaming for the set period
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/roaming
	 * @param    {PrepayProductRoamingRequest} req The API payload object (PrepayProductRoamingRequest)
	 * @return   {PrepayProductCommonRequestResponse} The API response object (PrepayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  PrepayProductRoamingRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID                UUID of PrepayProduct
	 * @property {Number}  StartRoamingAtNanos Nanos when roaming should be enabled from
	 * @property {Number}  EndRoamingAtNanos   Nanos when roaming should stop working
	 * @property {String}  CustomerUUID
	 *
	 * @typedef  {Object}  PrepayProductCommonRequestResponse
	 * @property {PrepayProduct} PrepayProduct      Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PrepayAddon} PrepayAddon
	 *
	 */
	PrepayProductRoaming({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/roaming`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PrepayProductRoaming ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PrepayProductRoaming ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PrepayProductRoaming ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PrepayProductRoaming UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PrepayProductRoaming'))
			})
	},

	/**
	 * RPC Method: PrepayProductSIMSwap
	 * Description: Swap to a new SIM on this prepay product
	 * HTTP Method: PUT
	 * API Path: /api/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/simswap
	 * @param    {PrepayProductSIMSwapRequest} req The API payload object (PrepayProductSIMSwapRequest)
	 * @return   {PrepayProductCommonRequestResponse} The API response object (PrepayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  PrepayProductSIMSwapRequest
	 * @property {Number}  SPID
	 * @property {String}  UUID         UUID of PrepayProduct
	 * @property {String}  SIMID        The SIMID to swap onto, the old SIM will be marked as ineligable
	 * @property {String}  CustomerUUID
	 *
	 * @typedef  {Object}  PrepayProductCommonRequestResponse
	 * @property {PrepayProduct} PrepayProduct      Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PrepayAddon} PrepayAddon
	 *
	 */
	PrepayProductSIMSwap({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/${req.SPID}/customer/${req.CustomerUUID}/mobileprepay/${req.UUID}/simswap`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PrepayProductSIMSwap ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PrepayProductSIMSwap ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PrepayProductSIMSwap ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PrepayProductSIMSwap UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PrepayProductSIMSwap'))
			})
	},

	/**
	 * RPC Method: PreprovisionSIMForPrepay
	 * Description: This API call takes loaded SIMs and MSISDNs (of type 'prepay') and preprovisions them for sale
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/mobileprepay_preprovision_special
	 * @param    {PrepaySpecialPreprovisonRequest} req The API payload object (PrepaySpecialPreprovisonRequest)
	 * @return   {PrepayPreprovisionResponse} The API response object (PrepayPreprovisionResponse)
	 *
	 * @typedef  {Object}  PrepaySpecialPreprovisonRequest
	 * @property {Number}  SPID
	 * @property {String}  ProductAvailabilityUUID UUID of PrepayProduct,  ProductAvailabilityUUID is the prepay product that is being provisioned onto the SIM
	 * @property {String}  AddonAvailabilityUUID   AddonUUID is the AddOn that should be provisioned when the SIM is activated by a customer,  there are some constraints, the retail price will be $0.00, it will not be configured to repeat,  the wholesaler will be charged the wholesale price
	 * @property {Number}  IMSI                    The start and end of the SIMID range to preprovision
	 * @property {Number}  MSISDN
	 *
	 * @typedef  {Object}  PrepayPreprovisionResponse
	 * @property {PrepaySIMDetails[]} PrepaySIMDetails
	 *
	 */
	PreprovisionSIMForPrepay({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/mobileprepay_preprovision_special`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PreprovisionSIMForPrepay ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PreprovisionSIMForPrepay ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PreprovisionSIMForPrepay ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PreprovisionSIMForPrepay UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PreprovisionSIMForPrepay'))
			})
	},

	/**
	 * RPC Method: PreprovisionSIMsForPrepay
	 * Description: This API call takes loaded SIMs and MSISDNs (of type 'prepay') and preprovisions them for sale
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/mobileprepay_preprovision
	 * @param    {PrepayPreprovisonRequest} req The API payload object (PrepayPreprovisonRequest)
	 * @return   {PrepayPreprovisionResponse} The API response object (PrepayPreprovisionResponse)
	 *
	 * @typedef  {Object}  PrepayPreprovisonRequest
	 * @property {Number}  SPID
	 * @property {String}  ProductAvailabilityUUID UUID of PrepayProduct,  ProductAvailabilityUUID is the prepay product that is being provisioned onto the SIM
	 * @property {String}  AddonAvailabilityUUID   AddonUUID is the AddOn that should be provisioned when the SIM is activated by a customer,  there are some constraints, the retail price will be $0.00, it will not be configured to repeat,  the wholesaler will be charged the wholesale price
	 * @property {Number}  IMSIRangeStart          The start and end of the SIMID range to preprovision
	 * @property {Number}  IMSIRangeCount
	 * @property {Boolean} Force                   The start and end of the MSISDN range to preprovosion,  int64 MSISDNRangeStart = 6;,  int64 MSISDNRangeEnd = 7;
	 *
	 * @typedef  {Object}  PrepayPreprovisionResponse
	 * @property {PrepaySIMDetails[]} PrepaySIMDetails
	 *
	 */
	PreprovisionSIMsForPrepay({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/mobileprepay_preprovision`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PreprovisionSIMsForPrepay ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PreprovisionSIMsForPrepay ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PreprovisionSIMsForPrepay ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PreprovisionSIMsForPrepay UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PreprovisionSIMsForPrepay'))
			})
	},

	/**
	 * RPC Method: PurgePrepayProductObjectByUUID
	 * Description: Purges a TemplateObject object. Requires JWT
	 * HTTP Method: DELETE
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}/purge
	 * @param    {PrepayProductUUIDSearch} req The API payload object (PrepayProductUUIDSearch)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProductUUIDSearch
	 * @property {String}  UUID
	 * @property {Number}  SPID Service Provider ID - always required
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	PurgePrepayProductObjectByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/mobileprepay/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePrepayProductObjectByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePrepayProductObjectByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePrepayProductObjectByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePrepayProductObjectByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePrepayProductObjectByUUID'))
			})
	},

	/**
	 * RPC Method: ReserveIMSIandMSISDN
	 * Description: This API call takes loaded SIMs and MSISDNs (of type 'prepay') and preprovisions them for sale
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/reserve_imsi
	 * @param    {ReserveIMSIandMSISDNRequest} req The API payload object (ReserveIMSIandMSISDNRequest)
	 * @return   {ReserveIMSIandMSISDNResponse} The API response object (ReserveIMSIandMSISDNResponse)
	 *
	 * @typedef  {Object}  ReserveIMSIandMSISDNRequest
	 * @property {Number}  SPID
	 * @property {Number}  StartIMSIRange
	 * @property {Number}  NumberOfReservations
	 *
	 * @typedef  {Object}  ReserveIMSIandMSISDNResponse
	 * @property {IMSIandMSISDN[]} IMSIandMSISDNs
	 *
	 */
	ReserveIMSIandMSISDN({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/reserve_imsi`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ReserveIMSIandMSISDN ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ReserveIMSIandMSISDN ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ReserveIMSIandMSISDN ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ReserveIMSIandMSISDN UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ReserveIMSIandMSISDN'))
			})
	},

	/**
	 * RPC Method: ResetPrepayProductByUUID
	 * Description: Resets the Prepay Product (OCS elements)
	 * HTTP Method: DELETE
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}/reset
	 * @param    {PrepayProductUUIDSearch} req The API payload object (PrepayProductUUIDSearch)
	 * @return   {PrepayProduct} The API response object (PrepayProduct)
	 *
	 * @typedef  {Object}  PrepayProductUUIDSearch
	 * @property {String}  UUID
	 * @property {Number}  SPID Service Provider ID - always required
	 *
	 * @typedef  {Object}  PrepayProduct
	 * @property {String}  UUID                         Status represents the status (or sub-status) of the product
	 * @property {Number}  SPID                         The direction of the port currently in action
	 * @property {Number}  CreatedAtNanos               When the object was first created
	 * @property {Number}  UpdatedAtNanos               When the object was last updated
	 * @property {Number}  DeletedAtNanos               When the object was deleted
	 * @property {String}  CustomerUUID                 Customer who this service belongs to
	 * @property {String}  MSISDN                       MSISDN on the prepay product
	 * @property {ENUM}    Status
	 * @property {Number}  RoamingFinishesAtNanos       Roaming - if 0 then no roaming enabled, if nanosecond then the date/time when roaming is disabled
	 * @property {Number}  RetailWholesalePercentSplit  Wholesale-retail split percentage -
	 * @property {ENUM}    PortingAction                PortingDirection is this an INBOUND or OUTBOUND port
	 * @property {ENUM}    PortingState
	 * @property {String}  PortingMSISDN
	 * @property {String}  PortingUUID
	 * @property {Number}  PortingRFS
	 * @property {String}  Name
	 * @property {String}  Description
	 * @property {Boolean} VoicemailEnabled             Options:,  Is voicemail enabled for this product
	 * @property {Number}  CreditCents                  Amount of Money balance that will be added
	 * @property {Number}  CreditSMS                    Amount of SMSs that are added
	 * @property {Number}  CreditVoiceMinutes           Amount of VoiceMinutes that are added
	 * @property {Number}  CreditDataMB                 Amount of Data MBs that are added
	 * @property {Number}  CreditMMS                    Amount of MMSs that are added
	 * @property {Number}  RolloverCreditSMS            Amount of SMSs that are added
	 * @property {Number}  RolloverCreditVoiceMinutes   Amount of VoiceMinutes that are added
	 * @property {Number}  RolloverCreditDataMB         Amount of Data MBs that are added
	 * @property {Number}  RolloverCreditMMS            Amount of MMSs that are added
	 * @property {PrepayAddon[]} AddOns                       List of current addons on this product
	 * @property {Number}  TotalSMS                     Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalVoiceMinutes            Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalDataMB                  Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalMMS                     Amount of MMSs that are added, including any rollover
	 * @property {Number}  CurrencyCreditExpiresAtNanos When the currency credit expires
	 * @property {Number}  TotalRolloverSMS             Total Amount of SMSs that are added, including any rollover
	 * @property {Number}  TotalRolloverVoiceMinutes    Total Amount of VoiceMinutes that are added, including any rollover
	 * @property {Number}  TotalRolloverDataMB          Total Amount of Data MBs that are added, including any rollover
	 * @property {Number}  TotalRolloverMMS             Total Amount of MMSs that are added, including any rollover
	 *
	 */
	ResetPrepayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.delete(`/v3/${req.SPID}/mobileprepay/${req.UUID}/reset`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('ResetPrepayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('ResetPrepayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('ResetPrepayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('ResetPrepayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in ResetPrepayProductByUUID'))
			})
	},

	/**
	 * RPC Method: TopupPrepayProductByUUID
	 * Description: Applies an AddOn to a PrepayProduct
	 * HTTP Method: POST
	 * API Path: /api/v3/${req.SPID}/mobileprepay/${req.UUID}/topup
	 * @param    {PrepayProductTopupRequest} req The API payload object (PrepayProductTopupRequest)
	 * @return   {PrepayProductCommonRequestResponse} The API response object (PrepayProductCommonRequestResponse)
	 *
	 * @typedef  {Object}  PrepayProductTopupRequest
	 * @property {Number}  SPID          AutoPurchase is used for PAYG auto renewed packages
	 * @property {String}  UUID          Can include this - UUID of the prepay product (ProductInstance UUID)
	 * @property {String}  MSISDN        Or this, MSISDN of the prepay product, will return error if MSISDN not found (obviously)
	 * @property {Number}  RetailCents   # of cents to retail credit the customers balance with
	 * @property {String}  Reference     A reference for this topup
	 * @property {String}  AddonsToApply Product Availability UUIDs of the addons to activate, returned as UUID from GetAvailablePrePayAddonsPaginated
	 * @property {ENUM}    TopupType     Topup Type
	 *
	 * @typedef  {Object}  PrepayProductCommonRequestResponse
	 * @property {PrepayProduct} PrepayProduct      Did the request succeed, if 0 then yes, if > 0 then an error code
	 * @property {ENUM}    ResponseStatus
	 * @property {String}  Reason             More details (technical) explaination of why it failed, or some kind of success message
	 * @property {Number}  RequiredTopupCents If extra credit is required, then this is the estimated amount
	 * @property {PrepayAddon} PrepayAddon
	 *
	 */
	TopupPrepayProductByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.post(`/v3/${req.SPID}/mobileprepay/${req.UUID}/topup`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('TopupPrepayProductByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('TopupPrepayProductByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('TopupPrepayProductByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('TopupPrepayProductByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in TopupPrepayProductByUUID'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};