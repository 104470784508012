import StoreHash from '@/utils/StoreHash';

const cached = {
    prepayProducts: {
        UUIDs: {}, // new StoreHash(5 * 60 * 1000),
        lifetime: 5 * 60 * 1000, // 5 min
    },
};

const state = {
    prepayProducts: {}
};

const getters = {
    prepayProductsByUUID(state){
        return UUID => {
            return state.prepayProducts[UUID];
        }
    },
};

const mutations = {
    mutPrepayProductsList(state, prepayProducts) {
        for (let i = 0, len = prepayProducts.length; i < len; i++) {
            const prepayProduct = prepayProducts[i];
            const UUID = prepayProduct.UUID;

            // Проверяем есть уже такой customer в кеше, если нет - кешируем
            if (!(UUID in cached.prepayProducts.UUIDs)) {
                cached.prepayProducts.UUIDs[UUID] = new StoreHash(cached.prepayProducts.lifetime);
            }

            state.prepayProducts[UUID] = prepayProduct;
            cached.prepayProducts.UUIDs[UUID].fix();
        }
    },

    mutPrepayProductsByUUID(state, prepayProduct) {
        const UUID = prepayProduct.UUID;
        state.prepayProducts[ UUID ] = prepayProduct;
    },
};

const actions = {
    getPrepayProduct({getters, commit, dispatch}, { SPID, UUID, CustomerUUID }){
        // Проверяем есть уже такой тикет в кеше, если нет - кешируем
        if (!(UUID in cached.prepayProducts.UUIDs)) {
            cached.prepayProducts.UUIDs[UUID] = new StoreHash(cached.prepayProducts.lifetime);
        }

        // Если вермя жизни кешированных данных истекло делаем запрос на сервер
        if (cached.prepayProducts.UUIDs[UUID].expired()) {
            return dispatch('api_prepay/GetPrepayProductByUUID', { SPID, UUID, CustomerUUID })
                .then(apidata => {
                    commit('mutPrepayProductsByUUID', apidata);
                    cached.prepayProducts.UUIDs[UUID].fix();
                    // Возвращаем геттер
                    return Promise.resolve( getters.prepayProductsByUUID(UUID) );
                }).catch(error => Promise.reject(error))
        } else {
            // Возвращаем геттер
            return Promise.resolve( getters.prepayProductsByUUID(UUID) );
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}