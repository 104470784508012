import Vue from 'vue'
import VueRouter from 'vue-router'
import permissions from './permissions'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: () => import('@/views/layouts/admin'), children: [
            { path: '',            name: 'home',      beforeEnter: (to, from, next) => next({ name: 'dashboard', replace: true }) },
            { path: 'dashboard',   name: 'dashboard',      component: () => import('@/views/dashboard-v2') },
            { path: 'dash',         name: 'dashboard-v1',  component: () => import('@/views/dashboard') },
            { path: 'user-edit',   name: 'user-edit',      component: () => import('@/views/user') },

            { path: 'customers', component: () => import('@/views/customers'),
                children: [
                    { path: '',     name: 'customers',    component: () => import('@/views/customers/list') },
                    { path: 'add',  name: 'add-customer', component: () => import('@/views/customers/add') },
                    { path: ':customerUUID',   component: () => import('@/views/customers/view'),
                        children: [
                            { path: '',           name: 'customer-details',   component: () => import('@/views/customers/details') },
                            { path: 'history',    name: 'payment-history',    component: () => import('@/views/customers/payment-history') },
                            { path: 'edit',       name: 'customer-edit',      component: () => import('@/views/customers/edit') },
                        ]
                    }
                ]
            },

            { path: 'services', component: () => import('@/views/services'),
                children: [
                    { path: '',         name: 'services',   component: () => import('@/views/services'), redirect: { name: 'prepay' } },
                    { path: 'prepay',   component: () => import('@/views/services/view'), children: [
                        { path: '', name: 'prepay', component: () => import('@/views/services/prepay') },
                        { path: ':uuid/:customerUUID', name: 'prepay-details', component: () => import('@/views/services/prepay-details') }
                    ] },
                    { path: 'postpay',   component: () => import('@/views/services/view'), children: [
                        { path: '', name: 'postpay', component: () => import('@/views/services/postpay') },
                        { path: ':uuid/:customerUUID',  name: 'postpay-details',    component: () => import('@/views/services/postpay-details') },
                        { path: 'add-service',          name: 'add-postpay',        component: () => import('@/views/services/add-postpay') },
                        { path: 'voice-services',       name: 'voice-services',     component: () => import('@/views/services/plan/voice-services') },
                        { path: 'sim-swap',             name: 'sim-swap',           component: () => import('@/views/services/plan/sim-swap') },
                        { path: 'change-plan',          name: 'change-plan',        component: () => import('@/views/services/plan/change-plan') },
                        { path: 'change-msisdn',        name: 'change-msisdn',      component: () => import('@/views/services/plan/change-msisdn') },
                        { path: 'sim-control-panel',    name: 'sim-control-panel',  component: () => import('@/views/services/plan/sim-control-panel') },
                    ] },

                    // { path: 'fwa', component: () => import('@/views/services/list'),
                    //     children: [
                    //         { path: '', component: () => import('@/views/services/service'),
                    //             children: [
                    //                 { path: '',             name: 'fwa',             component: () => import('@/views/services/service') },
                    //                 { path: 'active',       name: 'fwa-active',      component: () => import('@/views/services/service') },
                    //                 { path: 'inactive',     name: 'fwa-inactive',    component: () => import('@/views/services/service') },
                    //                 { path: 'pending',      name: 'fwa-pending',     component: () => import('@/views/services/service') },
                    //             ]
                    //         },
                    //         { path: ':uuid', component: () => import('@/views/services/view'),
                    //             children: [
                    //                 { path: '',     name: 'fwa-details', component: () => import('@/views/services/details') },
                    //                 { path: 'edit', name: 'fwa-edit',    component: () => import('@/views/services/edit') },
                    //             ]
                    //         },
                    //     ]
                    // }
                ]
            },

            { path: 'orders', component: () => import('@/views/orders'), children: [
                    { path: '',         name: 'orders',         component: () => import('@/views/orders'), redirect: { name: 'prepay-orders' } },
                    { path: 'prepay',   name: 'prepay-orders',  component: () => import('@/views/orders') },
                    { path: 'postpay',  name: 'postpay-orders', component: () => import('@/views/orders') },
                ]
            },

            { path: 'tickets', component: () => import('@/views/tickets'),
                children: [
                    { path: '',                 name: 'tickets',                component: () => import('@/views/tickets'), redirect: { name: 'tickets-dashboard' } },
                    { path: 'dashboard',        name: 'tickets-dashboard',      component: () => import('@/views/tickets/dashboard') },
                    { path: 'view',  component: () => import('@/views/tickets/view'),
                        children: [
                            { path: '',             name: 'tickets-all',        component: () => import('@/views/tickets/list') },
                            { path: 'new',          name: 'tickets-new',        component: () => import('@/views/tickets/list') },
                            { path: 'open',         name: 'tickets-open',       component: () => import('@/views/tickets/list') },
                            { path: 'pending',      name: 'tickets-pending',    component: () => import('@/views/tickets/list') },
                            { path: 'hold',         name: 'tickets-hold',       component: () => import('@/views/tickets/list') },
                            { path: 'solved',       name: 'tickets-solved',     component: () => import('@/views/tickets/list') },
                            { path: 'closed',       name: 'tickets-closed',     component: () => import('@/views/tickets/list') },
                            { path: 'id-:ticketId', name: 'tickets-details',    component: () => import('@/views/tickets/details') },
                        ]
                    },
                ]
            },

            { path: 'vouchers', component: () => import('@/views/vouchers'),
                children: [
                    { path: '', name: 'vouchers', component: () => import('@/views/vouchers'), redirect: { name: 'vouchers-search' } },
                    { path: 'search',   component: () => import('@/views/vouchers/search'),
                        children: [
                            { path: '',             name: 'vouchers-search',    component: () => import('@/views/vouchers/list'), },
                            { path: ':uuid',        name: 'vouchers-details',   component: () => import('@/views/vouchers/details'), },
                            { path: ':uuid/edit',   name: 'vouchers-edit',      component: () => import('@/views/vouchers/edit'), }
                        ]
                    },
                    { path: 'create',   name: 'vouchers-create',    component: () => import('@/views/vouchers/create') },
                ]
            },

            { path: 'commercial-view', name: 'commercial-view', component: () => import('@/views/commercial-view')},

            { path: 'number-porting', component: () => import('@/views/number-porting'),
                children: [
                    { path: '',         name: 'number-porting',     component: () => import('@/views/number-porting/list') },
                    { path: 'create',   name: 'create-porting',     component: () => import('@/views/number-porting/create') },
                    { path: ':uuid',  component: () => import('@/views/number-porting'),
                        children: [
                            { path: '',         name: 'porting-details',    component: () => import('@/views/number-porting/details') },
                            { path: 'edit',     name: 'porting-edit',       component: () => import('@/views/number-porting/edit') }
                        ]
                    },
                ]
            },

            { path: 'administration', name: 'administration', component: () => import('@/views/administration'), redirect: { name: 'user-management' },
                children: [
                    { path: 'user-management',              component: () => import('@/views/administration/user-management'),
                        children: [
                            { path: '', name: 'user-management', component: () => import('@/views/administration/user-management'), redirect: { name: 'teams' } },
                            { path: 'teams', component: () => import('@/views/administration/user-management'),
                                children: [
                                    { path: ':page(\\d+)?',     name: 'teams',          component: () => import('@/views/administration/user-management/user') },
                                    { path: ':uuid',            name: 'team-details',   component: () => import('@/views/administration/user-management/team/details') },
                                    { path: 'team-add',         name: 'team-add',       component: () => import('@/views/administration/user-management/team/add') },
                                    { path: ':uuid/team-edit',  name: 'team-edit',      component: () => import('@/views/administration/user-management/team/edit') },
                                ]
                            },
                            {  path: 'members', component: () => import('@/views/administration/user-management'),
                                children: [
                                    {  path: '',            name: 'members',        component: () => import('@/views/administration/user-management/user') },
                                    {  path: ':uuid',       name: 'member-details', component: () => import('@/views/administration/user-management/member/details') },
                                    {  path: 'member-add',  name: 'member-add',     component: () => import('@/views/administration/user-management/member/add') }
                                ]
                            },
                        ]
                    },
                    { path: 'api-key-management', component: () => import('@/views/administration/api-key-management'),
                        children: [
                            { path: '',     name: 'api-key-management', component: () => import('@/views/administration/api-key-management/list') },
                            { path: 'add',  name: 'api-key-add',        component: () => import('@/views/administration/api-key-management/add') },
                            { path: 'edit', name: 'api-key-edit',       component: () => import('@/views/administration/api-key-management/edit') },
                        ]
                    }
                ]
            },

            { path: 'preactivation', name: 'preactivation', component: () => import('@/views/preactivation'), redirect: { name: 'mobile-products' },
                children: [
                    { path: 'mobile-products', name: 'mobile-products',     component: () => import('@/views/preactivation/mobile-products') },
                ]
            },

            { path: 'change-password', name: 'change-password', component: () => import('@/views/change-password') },

            { path: '404', name: 'not-found', component: () => import('@/views/not-found') },
        ], beforeEnter: permissions(['is-auth'])},

        { path: '/', component: () => import('@/views/layouts/authorization'),
            children: [
                { path: 'sign-in',         name: 'sign-in',         component: () => import('@/views/sign-in') },
                { path: 'forgot-password', name: 'forgot-password', component: () => import('@/views/forgot-password') },
                { path: 'reset_password/:token', component: () => import('@/views/not-authenticated'),
                    children: [
                        { path: '', name: 'create-password', component: () => import('@/views/create-password') },
                    ]
                },
            ],
            beforeEnter: permissions(['!is-auth'], { name: 'dashboard' })},

        { path: '*', redirect: { name: 'not-found' },},
    ],
    scrollBehavior(to, from, savedPosition) {
        let scroll = { x: 0, y: 0 };

        if (to.hash) {
            scroll = { selector: to.hash }
        } else if (savedPosition) {
            scroll = savedPosition
        }
        return scroll
    },
})

export default router
