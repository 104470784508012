import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let hashed = {
    mobile_services: {
        uuids: {},
        lifetime: 5 * 60 * 1000, // 5 min
    }
}

const state = {
    mobile_services: {},
    mobile_services_plans: {},
}

const getters = {
    getMobileServiceByUUID: state => uuid => state.mobile_services[uuid],

    mobile_services_plans: state => state.mobile_services_plans,
}

const mutations = {
    saveMobileServicesPlans(state, plans) {
        let mobile_services_plans = {}

        for (let i = 0, len = plans.length; i < len; i++) {
            mobile_services_plans[plans[i].UUID] = plans[i]
        }

        state.mobile_services_plans = mobile_services_plans
    },

    saveMobileServices(state, services) {
        let services_list = {}

        for (let n = 0, len = services.length; n < len; n++) {
            const mobile_service = services[n]
            const uuid = mobile_service.UUID

            if (!(uuid in hashed.mobile_services.uuids)) {
                hashed.mobile_services.uuids[ uuid ] = new StoreHash(hashed.mobile_services.lifetime)
            }

            services_list[ uuid ] = mobile_service

            hashed.mobile_services.uuids[ uuid ].fix()
        }

        if (Object.keys(services_list).length) {
            state.mobile_services = {
                ...state.mobile_services,
                ...services_list
            }
        }
    },

    saveMobileService(state, service) {
        const uuid = service.UUID

        if (!(uuid in hashed.mobile_services.uuids)) {
            hashed.mobile_services.uuids[ uuid ] = new StoreHash(hashed.mobile_services.lifetime)
        }

        state.mobile_services = {
            ...state.mobile_services,
            [ uuid ]: service
        }

        hashed.mobile_services.uuids[ uuid ].fix()
    },

    deleteMobileServiceByUUID(state, uuid) {
        if (uuid in hashed.mobile_services.uuids) {
            delete hashed.mobile_services.uuids[ uuid ]
        }

        if (uuid in state.mobile_services) {
            delete state.mobile_services[ uuid ]
        }
    },

    resetHashedMobile(state) {
        state.mobile_services = {}

        hashed.mobile_services.uuids = {}
    }
}

const actions = {
    getPostpayMobilePlansPaginated({commit, dispatch, getters}, req) {
        const params = {
            ...req,
            SPID: getters.spid
        }
        return dispatch('api_postpay/FindPostpayMobilePlansPaginated', params)
            .then(response => {
                commit('saveMobileServicesPlans', response.PostpayMobilePlans);
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error))
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}