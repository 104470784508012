/**
 * Перехватчик запросов для axios:
 * - добавляет токен авторизации в заголовке X_Auth_Key при отправке каждого запроса
 * - обрабатывает полученнй результат по запросу
 * - разлогинивает юзера при получении 401
 * 
 * @param {*} Vue 
 * @param {*} axios 
 * @param {*} store 
 * @param {*} router 
 */
import errMessageApi from '@/helpers/errMessageApi';

const axios_config = {
    timeout: 10000,
    maxRedirects: 1,
    withCredentials: false,
}

const X_Auth_Key = 'x-auth-token';
const Service_Provider = 'SPID';

let authTries = 0;

function axiosMiddleware(Vue, axios, store, router) {
    axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

    const with_inactive_logout = store.getters.inactive_logout > 0;
    
    // добавляем токен авторизации
    // add auth token
    axios.interceptors.request.use(
        config => {
            if (config.admin === true) {
                config.baseURL = process.env.VUE_APP_API_ADMIN_HOST
            }
            
            if (with_inactive_logout) {
                store.dispatch('fixUserActivity').then(()=>{}).catch(()=>{})
            }

            if (!config.headers.common.hasOwnProperty(X_Auth_Key)) {
                const auth_token = store.getters.token

                if (auth_token) {
                    if (!config.params || !config.params.noauth) {
                        config.headers.common[X_Auth_Key] = auth_token
                    }
                }
            }

            const SPID = store.getters.spid;
            if (config.hasOwnProperty('isSPID') && config.isSPID) {
                if (SPID) {
                    if (!config.params || !config.params.noauth) {
                        config.headers.common[Service_Provider] = SPID;
                    }
                }
            }

            if (SPID) {
                config.url = config.url.replace(/:spid(\/|\?|$)/gi, `${ SPID }$1`)
            }

            // const currentCustomerUUID = store.getters.current_customeruuid
            // if (currentCustomerUUID) {
            //     config.url = config.url.replace(/:customeruuid(\/|\?|$)/gi, `${ currentCustomerUUID }$1`)
            //     config.url = config.url.replace(/:customer_uuid(\/|\?|$)/gi, `${ currentCustomerUUID }$1`)
            // }

            return {...axios_config, ...config}
        },
        error => Promise.reject(error)
    )

    axios.interceptors.response.use(
        // обрабатываем результат
        // process the result
        response => {
            response.result = response.data;
            response.apidata = response.data;
            response.errorStatus200 = response.data;

            // Иногда приходит статус 200 но в респонсе ошибка
            if (response.errorStatus200 && response.errorStatus200.err_code && response.errorStatus200.err_message) {
                switch (response.errorStatus200.err_code) {
                    case 401:
                        errMessageApi(null, 'Sorry, we were unable to log you in with those credentials')
                        break;

                    default:
                        errMessageApi(null, response.errorStatus200.err_message)
                }
                // return Promise.reject(new Error(response.errorStatus200));
            }

            if (response.headers && response.headers['x-auth-token'] && response.headers['x-auth-token-expiry']) {
                // If API returns a new X-Auth-Token, then we must replace our existing one
                // console.log('got new jwt:', response.headers['x-auth-token'])
                store.dispatch('setJWT', response.headers['x-auth-token'], response.headers['x-auth-token-expiry'])
            }
            authTries = 0;
            return Promise.resolve(response)
        },
        
        // разлогиниваем юзера
        // log off user
        error => {
            if (error.response) {
                switch (error.response.status) {
                    // 401: Unauthorized
                    case 401: {
                        
                        if (error.config.refresh === false) {
                            return Promise.reject(error);
                        } else if (error.response.data.err_number === 1002) {
                            /**
                             * err_number 1002
                             * err_message Missing authentication role
                             */

                            /**
                             * Иногда API возвращает 401 статус-код (Неавторизовано), вместо 403 (Запрещено)
                             * Например, если у пользователя недостаточно прав...
                             * Так как проблема не в сроке жизни токена, а в правах...
                             * Мы попадаем в бесконечный цикл: успешное обновление токена => запрос с 401, успешное обновление токена => запрос с 401, ...
                             */
                            errMessageApi(null, 'Sorry, we were unable to log you in with those credentials');
                            return store.dispatch('signOut').then(() => {
                                router.push({ name: 'sign-in' });
                                return Promise.reject(error);
                            })
                        } else {
                            const token = error.config.headers[X_Auth_Key];

                            authTries++;

                            if (authTries >= 10) {
                                return Promise.reject(error);
                            }

                            return store.dispatch('refreshToken', token).then(({ jwt }) => {
                                let config = error.config;
                                delete config.headers[X_Auth_Key];

                                return axios.request(error.config);
                            }).catch(error => {
                                return store.dispatch('signOut').then(() => {
                                    router.push({ name: 'sign-in' });
                                    return Promise.reject(error);
                                })
                            })
                        }

                    } break

                    // 403: Forbidden
                    case 403: {
                        error.response.data.err_message = '403: Forbidden';
                        return store.dispatch('signOut')
                            .then(() => {
                                router.push({ name: 'sign-in' });
                                return Promise.reject(error);
                            })
                    } break

                    default: {
                        authTries = 0;
                        return Promise.reject(error);
                    }
                }
            } else {
                authTries = 0;
                return Promise.reject(error);
            }
        }
    )
}

export default axiosMiddleware
