import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const cached = {
    customers: {
        ids: {}, // new StoreHash(5 * 60 * 1000),
        lifetime: 5 * 60 * 1000, // 5 min
    },
};

const valFuncFind = function (status) {
    return (status == 200) ? true : (status == 404) ? true : false;
}

const state = {
    customers: {}
};

const getters = {
    customerByID(state){
        return uuid => {
            return state.customers[uuid];
        }
    },
};

const mutations = {
    mutCustomersList(state, customers) {
        for (let i = 0, len = customers.length; i < len; i++) {
            const customer = customers[i];
            const id = customer.UUID;

            // Проверяем есть уже такой customer в кеше, если нет - кешируем
            if (!(id in cached.customers.ids)) {
                cached.customers.ids[id] = new StoreHash(cached.customers.lifetime);
            }

            state.customers[id] = customer;
            cached.customers.ids[id].fix();
        }
    },

    mutCustomerById(state, customer) {
        const uuid = customer.UUID;
        state.customers[ uuid ] = customer;

        // if (id in cached.forums.ids) {
        //     cached.forums.ids[id].reset();
        // }
    },

    mutUpdateCustomer(state, customer) {
        const uuid = customer.UUID;
        state.customers[ uuid ] = customer;

        cached.customers.ids[uuid].fix();
    },

    setCustomerById(state, customer){
        const id = customer.UUID;
        state.customers[ id ] = customer;

        // if (id in cached.customers.ids) {
        //     cached.customers.ids[id].reset();
        // }
    },
};

const actions = {
    getCustomers({ getters, commit }, params) {
        params.SPID = getters.spid;
        return axios.get(`/v2/:spid/customer`, { params, validateStatus: valFuncFind }).then(({apidata}) => {
            commit('mutCustomersList', apidata.Customers);
            return Promise.resolve(apidata)
        }).catch(error => Promise.reject(error))
    },


    getCustomerById({getters, commit}, uuid){
        // Проверяем есть уже такой тикет в кеше, если нет - кешируем
        if (!(uuid in cached.customers.ids)) {
            cached.customers.ids[uuid] = new StoreHash(cached.customers.lifetime);
        }

        // Если вермя жизни кешированных данных истекло делаем запрос на сервер
        if (cached.customers.ids[uuid].expired()) {
            return axios.get(`/v2/:spid/customer/${uuid}`).then(({apidata}) => {
                commit('mutCustomerById', apidata);

                cached.customers.ids[uuid].fix();

                // Возвращаем геттер
                return Promise.resolve( getters.customerByID(uuid) );
            }).catch(error => Promise.reject(error))
        } else {
            // Возвращаем геттер
            return Promise.resolve( getters.customerByID(uuid) );
        }
    },

    addCustomer({ commit }, customer) {
        return axios.post(`/v2/:spid/customer`, customer).then(apidata => {
            if (!apidata.data) {
                return Promise.reject(Error('no response data'))
            }

            // Ensure we save the response in cache
            commit('setCustomerById', apidata.data)
            return Promise.resolve(apidata.data)
        })
            .catch(error => {
                // Error response received from API
                if (error.response) {
                    // Server responded with a non-404 HTTP status code
                    console.log('AddCustomer ERROR Response:', error)
                    return Promise.reject(error)
                } else if (error.request) {
                    // Request was made but no response was received
                    console.log('AddCustomer ERROR making request:', error)
                    return Promise.reject(error)
                }
                // Something went wrong in setting up the request
                if (error) {
                    console.log('AddCustomer ERROR:', error)
                    return Promise.reject(error)
                }
                console.log('AddCustomer UNKNOWN ERROR')
                return Promise.reject(Error('unknown error in AddCustomer'))
            })
    },

    updateCustomer({ commit }, { customer, UUID }) {
        return axios.put(`/v2/:spid/customer/${UUID}`, customer).then(apidata => {
            // Success response
            if (!apidata.data) {
                return Promise.reject(Error('no response data'))
            }

            // Ensure we save the response in cache
            commit('mutUpdateCustomer', apidata.data)
            return Promise.resolve(apidata.data)
        })
            .catch(error => {
                // Error response received from API
                if (error.response) {
                    // Server responded with a non-404 HTTP status code
                    console.log('UpdateCustomer ERROR Response:', error)
                    return Promise.reject(error)
                } else if (error.request) {
                    // Request was made but no response was received
                    console.log('UpdateCustomer ERROR making request:', error)
                    return Promise.reject(error)
                }
                // Something went wrong in setting up the request
                if (error) {
                    console.log('UpdateCustomer ERROR:', error)
                    return Promise.reject(error)
                }
                console.log('UpdateCustomer UNKNOWN ERROR')
                return Promise.reject(Error('unknown error in UpdateCustomer'))
            })
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}