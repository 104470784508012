import axios from 'axios'
import axiosUnique from '@/utils/axiosUnique'

import StoreHash from '@/utils/StoreHash'

let cashed = {
    postpayMobilePlans: new StoreHash(1 * 60 * 1000), // 1 min,
}

const state = {
    postpayMobilePlans: {},
}

const getters = {
    postpayMobilePlans(state) {
        return state.postpayMobilePlans;
    },
}

const mutations = {
    mutSavePostpayMobilePlans(state, apidata) {
        state.postpayMobilePlans = apidata;
        cashed.postpayMobilePlans.fix();
    }
}

const actions = {
    FindCachedPostpayMobilePlansPaginated({ dispatch, getters, commit }, params) {
        if (cashed.postpayMobilePlans.expired()) {
            return dispatch('api_postpay/FindPostpayMobilePlansPaginated', params)
                .then(response => {
                    commit('mutSavePostpayMobilePlans', response)
                    return Promise.resolve(response)
                }).catch(error => {
                    return Promise.reject(error)
                })
        } else {
            return Promise.resolve(getters.postpayMobilePlans)
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}