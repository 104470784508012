import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const API_URL_RESELLER = process.env.VUE_APP_API_RESELLER_HOST;

const cashed = {
    paymentMethods: {
        uuids: {},
    },
    paymentMethodsAll: new StoreHash(5 * 60 * 1000), // 5 min
    paymentHistoryAll: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    paymentMethods: {},
    paymentHistory: {},
};

const getters = {
    paymentMethods(state) {
        if (state.paymentMethods && state.paymentMethods.length) {
            return state.paymentMethods
        }
        return []
    },
    paymentHistory(state) {
        return state.paymentHistory
    },
    paymentHistoryByYear(state) {
        return year => {
            return state.paymentHistory[year];
        }
    }
};

const mutations = {
    mutPaymentMethod(state, payments) {
        state.paymentMethods = payments;
        cashed.paymentMethodsAll.fix();
    },
    mutDeletePaymentMethod(state, UUID) {
        const payments = state.paymentMethods;
        state.paymentMethods = payments.filter(method => method.UUID !== UUID);
        cashed.paymentMethodsAll.fix();
    },
    mutPaymentRank(state, UUID) {
        const payments = state.paymentMethods;
        payments.forEach((payment, index) => {
            state.paymentMethods[index].Rank = 0;

            if (payment.UUID === UUID) {
                state.paymentMethods[index].Rank = 1;
            }
        });
        cashed.paymentMethodsAll.fix();
    },
    mutPaymentsHistory(state, apidata) {
        const year = apidata.Year;
        const paymentsPerYear = [];
        let sortedPaymentsByDate = [];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        months.forEach(month => {
            if (apidata[month] && apidata[month].length) {
                apidata[month].forEach(item => {
                    paymentsPerYear.push(item)
                })
            }
        });

        // if (paymentsPerYear.length > 0) {
        //     sortedPaymentsByDate = paymentsPerYear.sort((a, b) => {
        //         return new Date(b.CreatedAt) - new Date(a.CreatedAt)
        //     });
        // } else {
        //     sortedPaymentsByDate = [];
        // }

        state.paymentHistory[year] = paymentsPerYear.sort((a, b) => {
            return new Date(b.CreatedAt) - new Date(a.CreatedAt)
        });

        // state.paymentHistory[year] = sortedPaymentsByDate;
        cashed.paymentHistoryAll.fix();
    },
    resetPaymentsCashe() {
        cashed.paymentMethods.uuids = {};
        cashed.paymentMethodsAll.reset();
        cashed.paymentHistoryAll.reset();
    }
};

const actions = {
    getCashedPaymentMethods({getters, dispatch}, { CustomerUUID, refresh }) {
        if (cashed.paymentMethodsAll.expired() || refresh) {
            return dispatch('getPaymentMethods', { CustomerUUID, refresh })
        } else {
            return Promise.resolve(getters.paymentMethods)
        }
    },

    getPaymentMethods({ commit }, { CustomerUUID, refresh }) {
        return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods`, { params: { refresh } }).then(({apidata}) => {
            commit('mutPaymentMethod', apidata);
            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getCashedPaymentHistory({getters, dispatch}, { CustomerUUID, refresh, year }) {
        const currentYear = new Date().getFullYear();
        const isCachedData = year in getters.paymentHistory;

        // Если выбранный год меньше текущего и нет данных за этот год в сторе - делаем запрос на сервер
        // или
        // Если время кеширования истекло или есть запрос на refresh - делаем запрос на сервер
        if ((year < currentYear && !isCachedData) || (cashed.paymentHistoryAll.expired() || refresh)) {
            return dispatch('getPaymentHistory', { CustomerUUID, refresh, year })
        }

        return Promise.resolve(getters.paymentHistoryByYear(year))
    },

    getPaymentHistory({ getters, commit }, { CustomerUUID, refresh, year }) {
        return axios.get(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/charges/${year}`, { params: { refresh } }).then(({apidata}) => {
            commit('mutPaymentsHistory', apidata);
            return Promise.resolve(apidata)
        }).catch(error => {
            // commit('mutPaymentsHistory', {})
            return Promise.reject(error)
        })
    },

    deletePaymentMethods({ commit }, { CustomerUUID, UUID }) {
        return axios.delete(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods/${UUID}`).then(({apidata}) => {

            commit('mutDeletePaymentMethod', UUID)

            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    setDefaultPaymentMethod({ commit }, { CustomerUUID, UUID }) {
        return axios.put(`${API_URL_RESELLER}/admin/customer/${CustomerUUID}/payment-methods/${UUID}`, { Rank: 1 }).then(({apidata}) => {
            commit('mutPaymentRank', UUID);
            return Promise.resolve(apidata)
        }).catch(error => {
            return Promise.reject(error)
        })
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}