import axios from 'axios'

let requests = {}

class AxiosUnique {
    get() {
        const key = this.key(...arguments)

        if (key in requests) {
            return requests[key]
        } else {
            const request = new Promise((resolve, reject) => {
                axios.get(...arguments)
                    .then(response => {
                        delete requests[key]
                        resolve(response)
                    })
                    .catch(error => {
                        delete requests[key]
                        reject(error)
                    })
            })

            requests[key] = request

            return request
        }
    }

    key() {
        let args = (Array.from(arguments)).map(arg => {
            try {
                return JSON.stringify(arg)
            } catch (e) {
                return ''
            }
        })

        return args.join('|')
    }
}

export default new AxiosUnique()
