import Vue from 'vue'
import axios from 'axios'

import storage from '@/utils/storage'


const AUTH_URL = process.env.VUE_APP_API_HOST
const AUTH_SPID = 1
const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER

const GOOGLE_AUTH_ENABLED = process.env.VUE_APP_GOOGLE_AUTH_ENABLED
    ? isNaN(process.env.VUE_APP_GOOGLE_AUTH_ENABLED)
        ? process.env.VUE_APP_GOOGLE_AUTH_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_GOOGLE_AUTH_ENABLED > 0
    : false
const GOOGLE_AUTH_IDENTITY_PROVIDER = 'GOOGLE'

const MFA_AUTH_TOGGLER_ENABLED = process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED
    ? isNaN(process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED)
        ? process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED.trim().toLowerCase() == 'true'
        : process.env.VUE_APP_MFA_AUTH_TOGGLER_ENABLED > 0
    : false
const MFA_AUTH_TYPE = 'TOTP'

const KEY_AUTH_DATA = 'user'
const KEY_AUTH_TOKEN = 'token'

const state = {
    user: null,
    token: null,
    tokenExpiry: 0,
}

const getters = {
    isAuth: state => state.token ? Boolean(state.token) : false,
    token: state => state.token ? state.token : '',
    tokenExpiry: state => state.tokenExpiry ? state.tokenExpiry : 0,

    user: state => state.user,
    fullName: state => state.user ? `${state.user.FirstName} ${state.user.LastName}` : '',
    firstName: state => state.user ? state.user.FirstName : '',
    lastName: state => state.user ? state.user.LastName : '',
    email: state => state.user ? state.user.Email : '',
    mobileNumber: state => state.user ? state.user.MobileNumber : '',
    authID: state => state.user ? state.user.AuthID : null,
    identityProvider: state => state.user ? state.user.IdentityProvider : null,
    usedMFA: state => state.user ? state.user.UsedMFA : '',
    userSPID: state => state.user ? state.user.SPID : null,

    is_google_auth_enabled: state => GOOGLE_AUTH_ENABLED,
    is_mfa_auth_toggler_enabled: state => MFA_AUTH_TOGGLER_ENABLED,
}

const mutations = {
    mutSignIn(state, apidata) {
        state.token = apidata.JWT;
        state.tokenExpiry = apidata.JWTExpiry;
        state.user = apidata;

        storage.set(KEY_AUTH_TOKEN, apidata.JWT, apidata.JWTExpiry);
        storage.set(KEY_AUTH_DATA, apidata, null, true);
    },

    mutSignOut(state) {
        state.user = null;
        state.token = null;
        storage.remove(KEY_AUTH_DATA);
        storage.remove(KEY_AUTH_TOKEN);
    },

    mutJwt(state, token) {
        state.token = token;
    },

    // mut_update_jwt is desgined to be called from the Axios handler. When the
    // axios middleware recieves the X-Auth-Token + X-Auth-Token-Expiry headers
    // in the HTTP(S) response, then the backend has auto-renewed the token,
    // and it should call this to update the locally cached JWT and JWTExpiry
    mutUpdateJwt(state, { token, tokenExpiry }) {
        if (token &&
            tokenExpiry &&
            (tokenExpiry > Math.round(new Date().getTime() / 1000))
        ) {
            state.token = token;
            state.tokenExpiry = tokenExpiry;
            storage.set(KEY_AUTH_TOKEN, token, tokenExpiry);
        } else {
            state.user = null;
            state.token = null;
            state.tokenExpiry = 0;
            storage.remove(KEY_AUTH_TOKEN);
        }
    },

    setMFA(state, used_mfa) {
        if (state.user) {
            Vue.set(state.user, 'UsedMFA', used_mfa)
        }
    },
}

const actions = {
    authCheck({commit}, payload) {
        return axios.post(`${AUTH_URL}/v2/${AUTH_SPID}/auth_check`, payload).then(({ apidata }) => {
            if ('Roles' in apidata && apidata.Roles.length > 0) {
                for (let i = 0; i < apidata.Roles.length; i++) {
                    let role = apidata.Roles[i].RoleName;
                    if (role === 'SP_ADMIN' || role === 'SUPER_ADMIN') {
                        commit('mutSignIn', apidata);
                        return Promise.resolve(apidata);
                    }
                }
            }
            return Promise.reject(new Error('You do not have access to the portal - Please contact your account manager or administrator'))

        }).catch(error => error?.response?.data?.err_code == 412
            ? Promise.reject({
                'mode': 'MFA',
                payload,
                error
            })
            : Promise.reject(error)
        )
    },

    signIn({dispatch}, { email, password }) {
        const payload = {
            AuthID: email,
            AuthCode: password,
            IdentityProvider: IDENTITY_PROVIDER,
            IncludeRoles: true,
            SPID: AUTH_SPID,
        };

        return dispatch('authCheck', payload)
    },

    signInByGoogle({dispatch}) {
        return dispatch('googleSignIn').then(GoogleUser => {
            const payload = {
                IdentityProvider: GOOGLE_AUTH_IDENTITY_PROVIDER,
                AuthID: GoogleUser.getId() || 'anonymous',
                AuthNonce: GoogleUser.getAuthResponse().id_token,
                IncludeRoles: true,
                SPID: AUTH_SPID,
            }

            return dispatch('authCheck', payload)
        }).catch(error => Promise.reject(error))
    },

    signInByTOTP({dispatch}, { payload, code }) {
        return dispatch('authCheck', {...payload, MFACode: code})
            .then(data => Promise.resolve(data))
            .catch(error => error?.mode && error?.error
                ? error.error
                : error
            )
    },

    signOut({commit, dispatch}) {
        commit('mutSignOut');
        commit('mutResetTickets');

        dispatch('googleSignOut').catch(()=>{})

        return Promise.resolve(true);
    },

    // setJWT is called from axios middleware to update the local cache
    // of the JWT and JWT Expirty
    setJWT({ getters, commit, dispatch }, { token, tokenExpiry }) {
        if (!token || token == "-" || tokenExpiry == -1) {
            dispatch('signOut');
            return;
        };
        if (!tokenExpiry) {
            commit('mutUpdateJwt', { token, tokenExpiry: getters.tokenExpiry });
            return;
        };
        commit('mutUpdateJwt', { token, tokenExpiry });
    },

    refreshToken({ commit }, token) {
        const payload = {
            AuthCode: token,
            AuthID: 'jwt',
            IdentityProvider: 'JWT_REFRESH',
            IncludeRoles: true,
            SPID: AUTH_SPID,
        };
        return axios.post(`${AUTH_URL}/v2/${AUTH_SPID}/auth_check`, payload, { isSPID: false }).then(({ apidata }) => {
            commit('mutSignIn', apidata);
            return Promise.resolve(apidata);
        }).catch(error => Promise.reject(error));
    },

    loadAuthInfo({commit}) {
        const authData = storage.get(KEY_AUTH_DATA, true);
        const token = storage.get(KEY_AUTH_TOKEN);

        if (authData && token) {
            commit('mutSignIn', authData);
        }
    },

    addMFA({getters}) {
        if (getters.is_mfa_auth_toggler_enabled) {
            const payload = {
                AuthID: getters.authID,
                IdentityProvider: getters.identityProvider,
                MFAType: MFA_AUTH_TYPE,
            }
            
            return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/add_mfa`, payload)
                .then(({apidata}) => Promise.resolve(apidata))
                .catch(error => Promise.reject(error))
        } else {
            return Promise.reject(new Error('The MFA editing is not enabled'))
        }
    },

    removeMFA({getters}, code) {
        if (getters.is_mfa_auth_toggler_enabled) {
            const payload = {
                AuthID: getters.authID,
                IdentityProvider: getters.identityProvider,
                MFAType: MFA_AUTH_TYPE,
                MFACode: code,
            }
            
            return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/remove_mfa`, payload)
                .then(({apidata}) => Promise.resolve(apidata))
                .catch(error => Promise.reject(error))
        } else {
            return Promise.reject(new Error('The MFA editing is not enabled'))
        }
    },

    confirmMFA({getters}, code) {
        const payload = {
            AuthID: getters.authID,
            IdentityProvider: getters.identityProvider,
            MFAType: MFA_AUTH_TYPE,
            MFACode: code,
        }

        return axios.post(`${AUTH_URL}/v2/${getters.userSPID}/auth/confirm_mfa`, payload)
            .then(({apidata}) => Promise.resolve(apidata))
            .catch(error => Promise.reject(error))
    },

    setMFA({getters, commit}, used_mfa) {
        commit('setMFA', used_mfa)

        return Promise.resolve(getters.usedMFA)
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}