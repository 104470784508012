// Code generated by protoc-gen-iqwebapi. DO NOT EDIT.
// source: phonenumber.proto

import axios from 'axios';
import StoreHash from '@/utils/StoreHash';

const initialState = {
	NADEntries: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	NationalAllocations: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	NationalAllocationSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PhoneNumbers: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PhoneNumberSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PhoneNumberRanges: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PhoneNumberRangeSearches: {
		SearchTexts: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
	PhoneNumberRangeRoutings: {
		UUIDs: {},
		lifetime: 3 * 60 * 1000, // 3 minutes
	},
};

const valFuncFind = function (status) {
	return (status == 200) ? true : (status == 404) ? true : false;
}

const default_pageinfo = {
	PageNumber: 0,
	PageItemCount: 0,
	TotalPages: 0,
	TotalItemCount: 0,
	IndexStart: 0,
	PageSize: 10,
	DisplayPageList: [],
	DisplayPageEitherSide: 4,
}

const getters = {
	getNADEntryByUUID: state => (UUID) => (state.NADEntries.UUIDs[ UUID ] && !state.NADEntries.UUIDs[ UUID ].expired()) ?  state.NADEntries.UUIDs[ UUID ].hashedData() : null,
	getNationalAllocationByUUID: state => (UUID) => (state.NationalAllocations.UUIDs[ UUID ] && !state.NationalAllocations.UUIDs[ UUID ].expired()) ?  state.NationalAllocations.UUIDs[ UUID ].hashedData() : null,
	getNationalAllocationsForSearchText: state => (SearchText) => state.NationalAllocationSearches[ SearchText ] ? state.NationalAllocationSearches[ SearchText ] : null,
	getPhoneNumberByUUID: state => (UUID) => (state.PhoneNumbers.UUIDs[ UUID ] && !state.PhoneNumbers.UUIDs[ UUID ].expired()) ?  state.PhoneNumbers.UUIDs[ UUID ].hashedData() : null,
	getPhoneNumbersForSearchText: state => (SearchText) => state.PhoneNumberSearches[ SearchText ] ? state.PhoneNumberSearches[ SearchText ] : null,
	getPhoneNumberRangeByUUID: state => (UUID) => (state.PhoneNumberRanges.UUIDs[ UUID ] && !state.PhoneNumberRanges.UUIDs[ UUID ].expired()) ?  state.PhoneNumberRanges.UUIDs[ UUID ].hashedData() : null,
	getPhoneNumberRangesForSearchText: state => (SearchText) => state.PhoneNumberRangeSearches[ SearchText ] ? state.PhoneNumberRangeSearches[ SearchText ] : null,
	getPhoneNumberRangeRoutingByUUID: state => (UUID) => (state.PhoneNumberRangeRoutings.UUIDs[ UUID ] && !state.PhoneNumberRangeRoutings.UUIDs[ UUID ].expired()) ?  state.PhoneNumberRangeRoutings.UUIDs[ UUID ].hashedData() : null,
};

const mutations = {
	// mutSaveNADEntry saves a single NADEntry object
	// into both the StoreHash cache and the VueX store
	mutSaveNADEntry(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NADEntries.UUIDs)) {
			state.NADEntries.UUIDs[ obj.UUID ] = new StoreHash(state.NADEntries.lifetime)
		}
		state.NADEntries.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNADEntryByUUID removes a NADEntry object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNADEntryByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NADEntries.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNADEntries saves a paginated response of NADEntries
	mutSaveNADEntries(state, { Response }) {
		if (!Response || !Array.isArray(Response.NADEntries)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.NADEntries.length; i++) {
			if (!(Response.NADEntries[i].UUID in state.NADEntries.UUIDs)) {
				state.NADEntries.UUIDs[ Response.NADEntries[i].UUID ] = new StoreHash(state.NADEntries.lifetime)
			}
			state.NADEntries.UUIDs[ Response.NADEntries[i].UUID ].fix(Response.NADEntries[i])  // Add single NADEntry to StoreHash
		}
	},
	// mutSaveNationalAllocation saves a single NationalAllocation object
	// into both the StoreHash cache and the VueX store
	mutSaveNationalAllocation(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.NationalAllocations.UUIDs)) {
			state.NationalAllocations.UUIDs[ obj.UUID ] = new StoreHash(state.NationalAllocations.lifetime)
		}
		state.NationalAllocations.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemoveNationalAllocationByUUID removes a NationalAllocation object
	// with the given UUID from StoreHash cache and VueX store
	mutRemoveNationalAllocationByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.NationalAllocations.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSaveNationalAllocationSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSaveNationalAllocationSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.NationalAllocations)) {
			for (let i = 0; i < Response.NationalAllocations.length; i++) {
				storedObj.UUIDs.push(Response.NationalAllocations[i].UUID)
			}
		}
		state.NationalAllocationSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.NationalAllocationSearches.SearchTexts)) {
			state.NationalAllocationSearches.SearchTexts[ SearchText ] = new StoreHash(state.NationalAllocationSearches.lifetime)
		}
		state.NationalAllocationSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemoveNationalAllocationSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemoveNationalAllocationSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.NationalAllocationSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.NationalAllocationSearches[ SearchText ]               // remove item from VueX state
		delete state.NationalAllocationSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSavePhoneNumber saves a single PhoneNumber object
	// into both the StoreHash cache and the VueX store
	mutSavePhoneNumber(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PhoneNumbers.UUIDs)) {
			state.PhoneNumbers.UUIDs[ obj.UUID ] = new StoreHash(state.PhoneNumbers.lifetime)
		}
		state.PhoneNumbers.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePhoneNumberByUUID removes a PhoneNumber object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePhoneNumberByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PhoneNumbers.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePhoneNumberSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSavePhoneNumberSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.PhoneNumbers)) {
			for (let i = 0; i < Response.PhoneNumbers.length; i++) {
				storedObj.UUIDs.push(Response.PhoneNumbers[i].UUID)
			}
		}
		state.PhoneNumberSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.PhoneNumberSearches.SearchTexts)) {
			state.PhoneNumberSearches.SearchTexts[ SearchText ] = new StoreHash(state.PhoneNumberSearches.lifetime)
		}
		state.PhoneNumberSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemovePhoneNumberSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemovePhoneNumberSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.PhoneNumberSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.PhoneNumberSearches[ SearchText ]               // remove item from VueX state
		delete state.PhoneNumberSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSavePhoneNumbers saves a paginated response of PhoneNumbers
	mutSavePhoneNumbers(state, { Response }) {
		if (!Response || !Array.isArray(Response.PhoneNumbers)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.PhoneNumbers.length; i++) {
			if (!(Response.PhoneNumbers[i].UUID in state.PhoneNumbers.UUIDs)) {
				state.PhoneNumbers.UUIDs[ Response.PhoneNumbers[i].UUID ] = new StoreHash(state.PhoneNumbers.lifetime)
			}
			state.PhoneNumbers.UUIDs[ Response.PhoneNumbers[i].UUID ].fix(Response.PhoneNumbers[i])  // Add single PhoneNumber to StoreHash
		}
	},
	// mutSavePhoneNumberRange saves a single PhoneNumberRange object
	// into both the StoreHash cache and the VueX store
	mutSavePhoneNumberRange(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PhoneNumberRanges.UUIDs)) {
			state.PhoneNumberRanges.UUIDs[ obj.UUID ] = new StoreHash(state.PhoneNumberRanges.lifetime)
		}
		state.PhoneNumberRanges.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePhoneNumberRangeByUUID removes a PhoneNumberRange object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePhoneNumberRangeByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PhoneNumberRanges.UUIDs[ UUID ]    // remove item from StoreHash
	},
	// mutSavePhoneNumberRangeSearch saves a search response
	// with the given SearchText to the StoreHash cache and VueX store
	mutSavePhoneNumberRangeSearch(state, { SearchText, Response }) {
		if (!SearchText || !Response) {
			// Must have SearchText to Save and Response object
			return
		}
		let storedObj = {
			PageInfo: Object.assign({}, default_pageinfo), // shallow copy of default_pageinfo
			UUIDs: [],
		}
		// Deep copy ??? i.e. JSON.parse(JSON.stringify(obj));
		if (Response.PageInfo) {
			Response.PageInfo = Object.assign({}, Response.PageInfo) // shallow copy of the PageInfo
		}
		if (Array.isArray(Response.PhoneNumberRanges)) {
			for (let i = 0; i < Response.PhoneNumberRanges.length; i++) {
				storedObj.UUIDs.push(Response.PhoneNumberRanges[i].UUID)
			}
		}
		state.PhoneNumberRangeSearches[ SearchText ] = storedObj      // Add search text and list of UUIDs to VueX state

		if (!(SearchText in state.PhoneNumberRangeSearches.SearchTexts)) {
			state.PhoneNumberRangeSearches.SearchTexts[ SearchText ] = new StoreHash(state.PhoneNumberRangeSearches.lifetime)
		}
		state.PhoneNumberRangeSearches.SearchTexts[ SearchText ].fix() // Add item to StoreHash
	},
	// mutRemovePhoneNumberRangeSearch removes a search response
	// with the given SearchText from StoreHash cache and VueX store
	mutRemovePhoneNumberRangeSearch(state, SearchText) {
		if (!SearchText) {
			// Must have SearchText to Remove
			return
		}
		state.PhoneNumberRangeSearches[ SearchText ] = null               // cause VueX to trigger a data cascade
		delete state.PhoneNumberRangeSearches[ SearchText ]               // remove item from VueX state
		delete state.PhoneNumberRangeSearches.SearchTexts[ SearchText ]  // remove item from StoreHash
	},
	// mutSavePhoneNumberRanges saves a paginated response of PhoneNumberRanges
	mutSavePhoneNumberRanges(state, { Response }) {
		if (!Response || !Array.isArray(Response.PhoneNumberRanges)) {
			// Must have Response object with results
			return
		}
		for (let i = 0; i < Response.PhoneNumberRanges.length; i++) {
			if (!(Response.PhoneNumberRanges[i].UUID in state.PhoneNumberRanges.UUIDs)) {
				state.PhoneNumberRanges.UUIDs[ Response.PhoneNumberRanges[i].UUID ] = new StoreHash(state.PhoneNumberRanges.lifetime)
			}
			state.PhoneNumberRanges.UUIDs[ Response.PhoneNumberRanges[i].UUID ].fix(Response.PhoneNumberRanges[i])  // Add single PhoneNumberRange to StoreHash
		}
	},
	// mutSavePhoneNumberRangeRouting saves a single PhoneNumberRangeRouting object
	// into both the StoreHash cache and the VueX store
	mutSavePhoneNumberRangeRouting(state, obj) {
		if (!obj.UUID) { return } // Must have UUID to Save

		if (!(obj.UUID in state.PhoneNumberRangeRoutings.UUIDs)) {
			state.PhoneNumberRangeRoutings.UUIDs[ obj.UUID ] = new StoreHash(state.PhoneNumberRangeRoutings.lifetime)
		}
		state.PhoneNumberRangeRoutings.UUIDs[ obj.UUID ].fix(obj) // Add item to StoreHash
	},
	// mutRemovePhoneNumberRangeRoutingByUUID removes a PhoneNumberRangeRouting object
	// with the given UUID from StoreHash cache and VueX store
	mutRemovePhoneNumberRangeRoutingByUUID(state, UUID) {
		if (!UUID) {
			// Must have UUID to Remove
			return
		}
		delete state.PhoneNumberRangeRoutings.UUIDs[ UUID ]    // remove item from StoreHash
	},
};

const actions = {
	// getCachedNADEntryByUUID fetches a NADEntry from the cache
	// and if not in the cache, fetches it from the API
	getCachedNADEntryByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NADEntries.UUIDs[ UUID ] && !state.NADEntries.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NADEntries.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNADEntryByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNADEntry', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNADEntryByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedNationalAllocationByUUID fetches a NationalAllocation from the cache
	// and if not in the cache, fetches it from the API
	getCachedNationalAllocationByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.NationalAllocations.UUIDs[ UUID ] && !state.NationalAllocations.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.NationalAllocations.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetNationalAllocationByUUID', { UUID }).then(respPayload => {
			commit('mutSaveNationalAllocation', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemoveNationalAllocationByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPhoneNumberByUUID fetches a PhoneNumber from the cache
	// and if not in the cache, fetches it from the API
	getCachedPhoneNumberByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PhoneNumbers.UUIDs[ UUID ] && !state.PhoneNumbers.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PhoneNumbers.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPhoneNumberByUUID', { UUID }).then(respPayload => {
			commit('mutSavePhoneNumber', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePhoneNumberByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPhoneNumbersBySearch fetches PhoneNumbers from the cache
	// and if not in the cache, fetches it from the API
	getCachedPhoneNumbersBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.PhoneNumberSearches.SearchTexts[ SearchText ] && !state.PhoneNumberSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getPhoneNumbersForSearchText(SearchText)
			resp.PhoneNumbers = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.PhoneNumbers.push(getters.getPhoneNumberByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetPhoneNumbersPaginated', apiReq).then(respPayload => {
			commit('mutSavePhoneNumberSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePhoneNumberSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedPhoneNumberRangeByUUID fetches a PhoneNumberRange from the cache
	// and if not in the cache, fetches it from the API
	getCachedPhoneNumberRangeByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PhoneNumberRanges.UUIDs[ UUID ] && !state.PhoneNumberRanges.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PhoneNumberRanges.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPhoneNumberRangeByUUID', { UUID }).then(respPayload => {
			commit('mutSavePhoneNumberRange', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePhoneNumberRangeByUUID', UUID)
			return Promise.reject(error)
		})
	},

	// getCachedPhoneNumberRangesBySearch fetches PhoneNumberRanges from the cache
	// and if not in the cache, fetches it from the API
	getCachedPhoneNumberRangesBySearch({ state, getters, dispatch, commit }, { SPID, SearchText, RefreshCache }) {
		if (!RefreshCache && state.PhoneNumberRangeSearches.SearchTexts[ SearchText ] && !state.PhoneNumberRangeSearches.SearchTexts[ SearchText ].expired()) {
			// Return the cached records
			let resp = getters.getPhoneNumberRangesForSearchText(SearchText)
			resp.PhoneNumberRanges = []
			for (let i = 0; i < resp.UUIDs.length; i++) {
				resp.PhoneNumberRanges.push(getters.getPhoneNumberRangeByUUID(resp.UUIDs[i]))
			}
			return Promise.resolve(resp)
		}
		// Search using the API
		const apiReq = {
			SPID: SPID,
			SmartSearch: SearchText,
		}
		return dispatch('GetPhoneNumberRangesPaginated', apiReq).then(respPayload => {
			commit('mutSavePhoneNumberRangeSearch', { SearchText: SearchText, Response: respPayload })
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePhoneNumberRangeSearch', SearchText)
			return Promise.reject(error)
		})
	},

	// getCachedPhoneNumberRangeRoutingByUUID fetches a PhoneNumberRangeRouting from the cache
	// and if not in the cache, fetches it from the API
	getCachedPhoneNumberRangeRoutingByUUID({ state, dispatch, commit }, { UUID, RefreshCache }) {
		if (!RefreshCache && state.PhoneNumberRangeRoutings.UUIDs[ UUID ] && !state.PhoneNumberRangeRoutings.UUIDs[ UUID ].expired()) {
			// Return the cached record
			return state.PhoneNumberRangeRoutings.UUIDs[ UUID ].hashedData()
		}
		// Fetch from the API
		return dispatch('GetPhoneNumberRangeRoutingByUUID', { UUID }).then(respPayload => {
			commit('mutSavePhoneNumberRangeRouting', respPayload)
			return Promise.resolve(respPayload)
		}).catch(error => {
			commit('mutRemovePhoneNumberRangeRoutingByUUID', UUID)
			return Promise.reject(error)
		})
	},

	//
	// Service: PhoneNumberRPC
	// 
	/**
	* RPC Method: AddNADEntry
	* Description: Add a NADEntry object
	* HTTP Method: POST
	* API Path: /api/v3/nad_entry
	* @param    {NADEntry} req The API payload object (NADEntry)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	AddNADEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/nad_entry`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddNADEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddNADEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddNADEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddNADEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddNADEntry'))
			})
	},

	/**
	* RPC Method: AddPhoneNumber
	* Description: Add a PhoneNumber object
	* HTTP Method: POST
	* API Path: /api/v3/phone_number
	* @param    {PhoneNumber} req The API payload object (PhoneNumber)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	AddPhoneNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/phone_number`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPhoneNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPhoneNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPhoneNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPhoneNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPhoneNumber'))
			})
	},

	/**
	* RPC Method: AddPhoneNumberRange
	* Description: Add a PhoneNumberRange object
	* HTTP Method: POST
	* API Path: /api/v3/phone_number_range
	* @param    {PhoneNumberRange} req The API payload object (PhoneNumberRange)
	* @return   {PhoneNumberRange} The API response object (PhoneNumberRange)
	*
	* @typedef  {Object}  PhoneNumberRange
	* @property {String}  UUID           
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {Number}  SPID           Default Service Provider ID
	* @property {String}  NumberType     NumberType (if set, might be like FWA or others, freeform string, can be empty)
	* @property {String}  Description    Description
	* @property {String}  RangeStart     The start of the range
	* @property {String}  RangeEnd       The end of the range
	* @property {Number}  RangeStartInt  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt    The end of the range (represented as a uint64)
	* @property {Number}  CooldownLength The length of the cooldown when unallocated
	* @property {Boolean} IsCustomRange  Custom range skips NAD check, allowing range like 15 digits MSISDN
	*
	*/
	AddPhoneNumberRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Add)
		return axios.post(`/v3/phone_number_range`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AddPhoneNumberRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AddPhoneNumberRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AddPhoneNumberRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AddPhoneNumberRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AddPhoneNumberRange'))
			})
	},

	/**
	* RPC Method: AllocatePhoneNumberFromRange
	* Description: Create a single PhoneNumber object from the specified range
	* HTTP Method: GET
	* API Path: /api/v3/phone_number_range/${req.UUID}/allocate
	* @param    {PhoneNumberAllocationByRangeRequest} req The API payload object (PhoneNumberAllocationByRangeRequest)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumberAllocationByRangeRequest
	* @property {String}  UUID                  
	* @property {Number}  SPID                  Service Provider ID - always required
	* @property {ENUM}    AllocationRequestType 
	* @property {String}  SpecificNumber        
	* @property {String}  CustomerUUID          
	* @property {String}  ServiceUUID           
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	AllocatePhoneNumberFromRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/phone_number_range/${paramUUID}/allocate`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('AllocatePhoneNumberFromRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('AllocatePhoneNumberFromRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('AllocatePhoneNumberFromRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('AllocatePhoneNumberFromRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in AllocatePhoneNumberFromRange'))
			})
	},

	/**
	* RPC Method: DeleteNADEntryByUUID
	* Description: Deletes a NADEntry object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/nad_entry/${req.UUID}
	* @param    {NADEntryUUIDSearch} req The API payload object (NADEntryUUIDSearch)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntryUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	DeleteNADEntryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/nad_entry/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNADEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeleteNADEntryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeleteNADEntryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeleteNADEntryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeleteNADEntryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeleteNADEntryByUUID'))
			})
	},

	/**
	* RPC Method: DeletePhoneNumberByUUID
	* Description: Deletes a PhoneNumber object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/phone_number/${req.UUID}
	* @param    {PhoneNumberUUIDSearch} req The API payload object (PhoneNumberUUIDSearch)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumberUUIDSearch
	* @property {String}  UUID                   
	* @property {Number}  SPID                   Service Provider ID - always required
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {String}  ServiceUUID            (optional) ServiceUUID
	* @property {String}  PhoneNumber            (optional) PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	DeletePhoneNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/phone_number/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePhoneNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePhoneNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePhoneNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePhoneNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePhoneNumberByUUID'))
			})
	},

	/**
	* RPC Method: DeletePhoneNumberRangeByUUID
	* Description: Deletes a PhoneNumberRange object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/phone_number_range/${req.UUID}
	* @param    {PhoneNumberRangeUUIDSearch} req The API payload object (PhoneNumberRangeUUIDSearch)
	* @return   {PhoneNumberRange} The API response object (PhoneNumberRange)
	*
	* @typedef  {Object}  PhoneNumberRangeUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  PhoneNumberRange
	* @property {String}  UUID           
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {Number}  SPID           Default Service Provider ID
	* @property {String}  NumberType     NumberType (if set, might be like FWA or others, freeform string, can be empty)
	* @property {String}  Description    Description
	* @property {String}  RangeStart     The start of the range
	* @property {String}  RangeEnd       The end of the range
	* @property {Number}  RangeStartInt  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt    The end of the range (represented as a uint64)
	* @property {Number}  CooldownLength The length of the cooldown when unallocated
	* @property {Boolean} IsCustomRange  Custom range skips NAD check, allowing range like 15 digits MSISDN
	*
	*/
	DeletePhoneNumberRangeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Delete)
		return axios.delete(`/v3/phone_number_range/${req.UUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumberRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('DeletePhoneNumberRangeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('DeletePhoneNumberRangeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('DeletePhoneNumberRangeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('DeletePhoneNumberRangeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in DeletePhoneNumberRangeByUUID'))
			})
	},

	/**
	* RPC Method: FindAvailablePhoneNumbersByType
	* Description: Lists out all available phone numbers
	* HTTP Method: GET
	* API Path: /api/v3/${req.SPID}/phone_numbers_available
	* @param    {PhoneNumberAvailabilitySearchRequest} req The API payload object (PhoneNumberAvailabilitySearchRequest)
	* @return   {PhoneNumberAvailabilitySearchResponse} The API response object (PhoneNumberAvailabilitySearchResponse)
	*
	* @typedef  {Object}  PhoneNumberAvailabilitySearchRequest
	* @property {SearchOptions[]} SearchOptions  
	* @property {Boolean} IncludeDeleted 
	* @property {String}  NumberType     
	* @property {Number}  SPID           Service Provider ID - always required
	*
	* @typedef  {Object}  PhoneNumberAvailabilitySearchResponse
	* @property {AvailablePhoneNumber[]} AvailablePhoneNumbers 
	*
	*/
	FindAvailablePhoneNumbersByType({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramSPID = encodeURIComponent(req.SPID)
		return axios.get(`/v3/${paramSPID}/phone_numbers_available`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('FindAvailablePhoneNumbersByType ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('FindAvailablePhoneNumbersByType ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('FindAvailablePhoneNumbersByType ERROR:', error)
					return Promise.reject(error)
				}
				console.log('FindAvailablePhoneNumbersByType UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in FindAvailablePhoneNumbersByType'))
			})
	},

	/**
	* RPC Method: GetNADEntriesPaginated
	* Description: Searches for multiple NADEntry objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/nad_entry
	* @param    {NADEntrySearchRequest} req The API payload object (NADEntrySearchRequest)
	* @return   {NADEntryPageResponse} The API response object (NADEntryPageResponse)
	*
	* @typedef  {Object}  NADEntrySearchRequest
	* @property {SearchOptions[]} SearchOptions  Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted 
	* @property {Number}  SPID           
	* @property {String}  UUID           (optional) UUID of the range
	* @property {String}  Code           Search by Code
	* @property {String}  LICA           
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  NADEntryPageResponse
	* @property {PageInfo} PageInfo   PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {NADEntry[]} NADEntries 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetNADEntriesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/nad_entry`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSaveNADEntries', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNADEntriesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNADEntriesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNADEntriesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNADEntriesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNADEntriesPaginated'))
			})
	},

	/**
	* RPC Method: GetNADEntryByPhoneNumber
	* Description: Get a single NADEntry object using a Phone number
	* HTTP Method: GET
	* API Path: /api/v3/nad_entry_phone_number/${req.PhoneNumber}
	* @param    {NADEntryPhoneNumberSearch} req The API payload object (NADEntryPhoneNumberSearch)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntryPhoneNumberSearch
	* @property {String}  PhoneNumber 
	* @property {Number}  SPID        Service Provider ID - always required
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	GetNADEntryByPhoneNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramPhoneNumber = encodeURIComponent(req.PhoneNumber)
		return axios.get(`/v3/nad_entry_phone_number/${paramPhoneNumber}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNADEntryByPhoneNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNADEntryByPhoneNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNADEntryByPhoneNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNADEntryByPhoneNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNADEntryByPhoneNumber'))
			})
	},

	/**
	* RPC Method: GetNADEntryByUUID
	* Description: Get a single NADEntry object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/nad_entry/${req.UUID}
	* @param    {NADEntryUUIDSearch} req The API payload object (NADEntryUUIDSearch)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntryUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	GetNADEntryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/nad_entry/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNADEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetNADEntryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetNADEntryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetNADEntryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetNADEntryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetNADEntryByUUID'))
			})
	},

	/**
	* RPC Method: GetPhoneNumberByPhoneNumber
	* Description: Get a PhoneNumber object using PhoneNumber.
	* HTTP Method: PUT
	* API Path: /api/v3/phone_number_by_phone_number/${req.UUID}
	* @param    {PhoneNumber} req The API payload object (PhoneNumber)
	* @return   {PhoneNumbers} The API response object (PhoneNumbers)
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	* @typedef  {Object}  PhoneNumbers
	* @property {PhoneNumber[]} PhoneNumbers 
	*
	*/
	GetPhoneNumberByPhoneNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/phone_number_by_phone_number/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPhoneNumberByPhoneNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPhoneNumberByPhoneNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPhoneNumberByPhoneNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPhoneNumberByPhoneNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPhoneNumberByPhoneNumber'))
			})
	},

	/**
	* RPC Method: GetPhoneNumberByUUID
	* Description: Get a single PhoneNumber object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/phone_number/${req.UUID}
	* @param    {PhoneNumberUUIDSearch} req The API payload object (PhoneNumberUUIDSearch)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumberUUIDSearch
	* @property {String}  UUID                   
	* @property {Number}  SPID                   Service Provider ID - always required
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {String}  ServiceUUID            (optional) ServiceUUID
	* @property {String}  PhoneNumber            (optional) PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	GetPhoneNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/phone_number/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPhoneNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPhoneNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPhoneNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPhoneNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPhoneNumberByUUID'))
			})
	},

	/**
	* RPC Method: GetPhoneNumberRangeByUUID
	* Description: Get a single PhoneNumberRange object using the UUID
	* HTTP Method: GET
	* API Path: /api/v3/phone_number_range/${req.UUID}
	* @param    {PhoneNumberRangeUUIDSearch} req The API payload object (PhoneNumberRangeUUIDSearch)
	* @return   {PhoneNumberRange} The API response object (PhoneNumberRange)
	*
	* @typedef  {Object}  PhoneNumberRangeUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  PhoneNumberRange
	* @property {String}  UUID           
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {Number}  SPID           Default Service Provider ID
	* @property {String}  NumberType     NumberType (if set, might be like FWA or others, freeform string, can be empty)
	* @property {String}  Description    Description
	* @property {String}  RangeStart     The start of the range
	* @property {String}  RangeEnd       The end of the range
	* @property {Number}  RangeStartInt  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt    The end of the range (represented as a uint64)
	* @property {Number}  CooldownLength The length of the cooldown when unallocated
	* @property {Boolean} IsCustomRange  Custom range skips NAD check, allowing range like 15 digits MSISDN
	*
	*/
	GetPhoneNumberRangeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Get)
		// Prepare URI params...
		let paramUUID = encodeURIComponent(req.UUID)
		return axios.get(`/v3/phone_number_range/${paramUUID}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumberRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPhoneNumberRangeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPhoneNumberRangeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPhoneNumberRangeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPhoneNumberRangeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPhoneNumberRangeByUUID'))
			})
	},

	/**
	* RPC Method: GetPhoneNumberRangesPaginated
	* Description: Searches for multiple PhoneNumberRange objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/phone_number_range
	* @param    {PhoneNumberRangeSearchRequest} req The API payload object (PhoneNumberRangeSearchRequest)
	* @return   {PhoneNumberRangePageResponse} The API response object (PhoneNumberRangePageResponse)
	*
	* @typedef  {Object}  PhoneNumberRangeSearchRequest
	* @property {SearchOptions[]} SearchOptions      Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted     
	* @property {String}  UUID               (optional) UUID of the range
	* @property {Number}  SPID               (optional) SPID
	* @property {String}  PhoneNumberInRange (optional) PhoneNumberInRange is one of the numbers in this range
	* @property {String}  SmartSearch        SmartSearch
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PhoneNumberRangePageResponse
	* @property {PageInfo} PageInfo          PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PhoneNumberRange[]} PhoneNumberRanges 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPhoneNumberRangesPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/phone_number_range`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePhoneNumberRanges', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPhoneNumberRangesPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPhoneNumberRangesPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPhoneNumberRangesPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPhoneNumberRangesPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPhoneNumberRangesPaginated'))
			})
	},

	/**
	* RPC Method: GetPhoneNumbersPaginated
	* Description: Searches for multiple PhoneNumber objects using a search request
	* HTTP Method: GET
	* API Path: /api/v3/phone_number
	* @param    {PhoneNumberSearchRequest} req The API payload object (PhoneNumberSearchRequest)
	* @return   {PhoneNumberPageResponse} The API response object (PhoneNumberPageResponse)
	*
	* @typedef  {Object}  PhoneNumberSearchRequest
	* @property {SearchOptions[]} SearchOptions          Search options to use (for sorting and/or pagination)
	* @property {Boolean} IncludeDeleted         
	* @property {Number}  SPID                   (optional) SPID
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {String}  ServiceUUID            (optional) ProductInstanceUUID
	* @property {String}  PhoneNumber            (optional) PhoneNumber
	* @property {ENUM}    NumberAllocationStatus (optional) Allocation status
	* @property {String}  PhoneNumberRangeUUID   (optional) Phone Number Range UUID
	* @property {String}  SourceSystem           (optional) Source of number "ipms" or "internal"
	*
	* @typedef  {Object}  SearchOptions  Search options
	* @property {Number}  PageNumber     The page number to start at (or provide IndexStart)
	* @property {Number}  PageSize       The page size
	* @property {Number}  IndexStart     The record number to start at (or provide PageNumber)
	* @property {String}  SortBy         The field to sort by
	* @property {Boolean} SortDesc       Is the sort direction descending?
	*
	* @typedef  {Object}  PhoneNumberPageResponse
	* @property {PageInfo} PageInfo     PageInfo only needs the values ThisPageNumber && PageSize set
	* @property {PhoneNumber[]} PhoneNumbers 
	*
	* @typedef  {Object}   PageInfo
	* @property {Number}   PageNumber             The page number
	* @property {Number}   PageItemCount          Number of items on this page
	* @property {Number}   TotalPages             Total pages available
	* @property {Number}   TotalItemCount         Total items available
	* @property {Number}   IndexStart             The record number this search started at
	* @property {Number}   PageSize               The length of the page
	* @property {Number[]} DisplayPageList        The array of page numbers to display
	* @property {Number}   DisplayPagesEitherSide Number of pages to display either side
	*
	*/
	GetPhoneNumbersPaginated({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Find)
		return axios.get(`/v3/phone_number`, { params: req, validateStatus: valFuncFind }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			commit('mutSavePhoneNumbers', { Response: response.data })
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetPhoneNumbersPaginated ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetPhoneNumbersPaginated ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetPhoneNumbersPaginated ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetPhoneNumbersPaginated UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetPhoneNumbersPaginated'))
			})
	},

	/**
	* RPC Method: GetSPIDByPhoneNumber
	* Description: Get the service provider ID for a given number
	* HTTP Method: GET
	* API Path: /api/v3/spid_by_phonenumber/${req.PhoneNumber}
	* @param    {GetSPIDByPhoneNumberRequest} req The API payload object (GetSPIDByPhoneNumberRequest)
	* @return   {GetSPIDByPhoneNumberResponse} The API response object (GetSPIDByPhoneNumberResponse)
	*
	* @typedef  {Object}  GetSPIDByPhoneNumberRequest
	* @property {Number}  SPID        
	* @property {String}  PhoneNumber 
	*
	* @typedef  {Object}  GetSPIDByPhoneNumberResponse
	* @property {Number}  SPID        
	* @property {String}  ServiceUUID 
	*
	*/
	GetSPIDByPhoneNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		// Prepare URI params...
		let paramPhoneNumber = encodeURIComponent(req.PhoneNumber)
		return axios.get(`/v3/spid_by_phonenumber/${paramPhoneNumber}`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetSPIDByPhoneNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetSPIDByPhoneNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetSPIDByPhoneNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetSPIDByPhoneNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetSPIDByPhoneNumber'))
			})
	},

	/**
	* RPC Method: GetServiceUUIDsByPhoneNumbers
	* Description: Get a list of ServiceUUID's using PhoneNumbers.
	* HTTP Method: PUT
	* API Path: /api/v3/phone_number_by_phone_number/${req.PhoneNumbers}
	* @param    {ServiceUUIDByPhoneNumberRequest} req The API payload object (ServiceUUIDByPhoneNumberRequest)
	* @return   {ServiceUUIDByPhoneNumberResponse} The API response object (ServiceUUIDByPhoneNumberResponse)
	*
	* @typedef  {Object}  ServiceUUIDByPhoneNumberRequest
	* @property {String}  PhoneNumbers 
	* @property {Number}  SPID         
	*
	* @typedef  {Object}  ServiceUUIDByPhoneNumberResponse
	* @property {String}  PhoneNumbers 
	* @property {String}  ServiceUUID  
	*
	*/
	GetServiceUUIDsByPhoneNumbers({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.put(`/v3/phone_number_by_phone_number/${req.PhoneNumbers}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('GetServiceUUIDsByPhoneNumbers ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('GetServiceUUIDsByPhoneNumbers ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('GetServiceUUIDsByPhoneNumbers ERROR:', error)
					return Promise.reject(error)
				}
				console.log('GetServiceUUIDsByPhoneNumbers UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in GetServiceUUIDsByPhoneNumbers'))
			})
	},

	/**
	* RPC Method: PurgeNADEntryByUUID
	* Description: Purges a NADEntry object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/nad_entry/${req.UUID}/purge
	* @param    {NADEntryUUIDSearch} req The API payload object (NADEntryUUIDSearch)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntryUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	PurgeNADEntryByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/nad_entry/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNADEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgeNADEntryByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgeNADEntryByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgeNADEntryByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgeNADEntryByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgeNADEntryByUUID'))
			})
	},

	/**
	* RPC Method: PurgePhoneNumberByUUID
	* Description: Purges a PhoneNumber object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/phone_number/${req.UUID}/purge
	* @param    {PhoneNumberUUIDSearch} req The API payload object (PhoneNumberUUIDSearch)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumberUUIDSearch
	* @property {String}  UUID                   
	* @property {Number}  SPID                   Service Provider ID - always required
	* @property {String}  CustomerUUID           (optional) CustomerUUID
	* @property {String}  ServiceUUID            (optional) ServiceUUID
	* @property {String}  PhoneNumber            (optional) PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	PurgePhoneNumberByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/phone_number/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePhoneNumberByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePhoneNumberByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePhoneNumberByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePhoneNumberByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePhoneNumberByUUID'))
			})
	},

	/**
	* RPC Method: PurgePhoneNumberRangeByUUID
	* Description: Purges a PhoneNumberRange object. Requires JWT
	* HTTP Method: DELETE
	* API Path: /api/v3/phone_number_range/${req.UUID}/purge
	* @param    {PhoneNumberRangeUUIDSearch} req The API payload object (PhoneNumberRangeUUIDSearch)
	* @return   {PhoneNumberRange} The API response object (PhoneNumberRange)
	*
	* @typedef  {Object}  PhoneNumberRangeUUIDSearch
	* @property {String}  UUID 
	* @property {Number}  SPID Service Provider ID - always required
	*
	* @typedef  {Object}  PhoneNumberRange
	* @property {String}  UUID           
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {Number}  SPID           Default Service Provider ID
	* @property {String}  NumberType     NumberType (if set, might be like FWA or others, freeform string, can be empty)
	* @property {String}  Description    Description
	* @property {String}  RangeStart     The start of the range
	* @property {String}  RangeEnd       The end of the range
	* @property {Number}  RangeStartInt  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt    The end of the range (represented as a uint64)
	* @property {Number}  CooldownLength The length of the cooldown when unallocated
	* @property {Boolean} IsCustomRange  Custom range skips NAD check, allowing range like 15 digits MSISDN
	*
	*/
	PurgePhoneNumberRangeByUUID({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Purge)
		return axios.delete(`/v3/phone_number_range/${req.UUID}/purge`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumberRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('PurgePhoneNumberRangeByUUID ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('PurgePhoneNumberRangeByUUID ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('PurgePhoneNumberRangeByUUID ERROR:', error)
					return Promise.reject(error)
				}
				console.log('PurgePhoneNumberRangeByUUID UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in PurgePhoneNumberRangeByUUID'))
			})
	},

	/**
	* RPC Method: UpdateNADEntry
	* Description: Update a single NADEntry object
	* HTTP Method: PUT
	* API Path: /api/v3/nad_entry/${req.UUID}
	* @param    {NADEntry} req The API payload object (NADEntry)
	* @return   {NADEntry} The API response object (NADEntry)
	*
	* @typedef  {Object}  NADEntry
	* @property {String}  UUID                
	* @property {Number}  SPID                
	* @property {String}  ServiceCategoryCode 
	* @property {String}  Code                
	* @property {String}  ApplicantName       
	* @property {String}  Status              
	* @property {String}  ApplicationCategory 
	* @property {String}  LICA                
	* @property {String}  LocalCallingArea    
	* @property {String}  Level               
	* @property {Number}  ExpiryDate          
	* @property {Number}  Date                
	* @property {Number}  CreatedAtNanos      
	* @property {Number}  UpdatedAtNanos      
	* @property {Number}  DeletedAtNanos      
	*
	*/
	UpdateNADEntry({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/nad_entry/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSaveNADEntry', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateNADEntry ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateNADEntry ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateNADEntry ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateNADEntry UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateNADEntry'))
			})
	},

	/**
	* RPC Method: UpdateNADFromWeb
	* Description: Update NADEntries from web
	* HTTP Method: GET
	* API Path: /api/v3/update_nad_from_web
	* @param    {UpdateReqResp} req The API payload object (UpdateReqResp)
	* @return   {UpdateReqResp} The API response object (UpdateReqResp)
	*
	* @typedef  {Object}  UpdateReqResp
	* @property {Number}  SPID   
	* @property {String}  Status 
	*
	*/
	UpdateNADFromWeb({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request 
		return axios.get(`/v3/update_nad_from_web`, { params: req }).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdateNADFromWeb ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdateNADFromWeb ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdateNADFromWeb ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdateNADFromWeb UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdateNADFromWeb'))
			})
	},

	/**
	* RPC Method: UpdatePhoneNumber
	* Description: Update a single PhoneNumber object
	* HTTP Method: PUT
	* API Path: /api/v3/phone_number/${req.UUID}
	* @param    {PhoneNumber} req The API payload object (PhoneNumber)
	* @return   {PhoneNumber} The API response object (PhoneNumber)
	*
	* @typedef  {Object}  PhoneNumber
	* @property {String}  UUID                   
	* @property {Number}  CreatedAtNanos         When the object was first created
	* @property {Number}  UpdatedAtNanos         When the object was last updated
	* @property {Number}  DeletedAtNanos         When the object was deleted
	* @property {Number}  SPID                   Default Service Provider ID
	* @property {String}  CustomerUUID           CustomerUUID, if allocated to a customer
	* @property {String}  ServiceUUID            ServiceUUID, if allocated to a Service
	* @property {String}  PhoneNumber            PhoneNumber
	* @property {ENUM}    NumberAllocationStatus Allocation status
	* @property {String}  PhoneNumberRangeUUID   PhoneNumberRangeUUID, if allocated from a PhoneNumberRange
	* @property {Number}  RangeStartInt          If Unallocated, set start and end ranges,  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt            The end of the range (represented as a uint64)
	* @property {Number}  UnallocationAtNanos    When this number should be moved from cooldown to unallocated
	* @property {String}  SourceSystem           SourceSystem, could be ipms or internal, allows for differing policies?
	*
	*/
	UpdatePhoneNumber({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/phone_number/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumber', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePhoneNumber ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePhoneNumber ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePhoneNumber ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePhoneNumber UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePhoneNumber'))
			})
	},

	/**
	* RPC Method: UpdatePhoneNumberRange
	* Description: Update a single PhoneNumberRange object
	* HTTP Method: PUT
	* API Path: /api/v3/phone_number_range/${req.UUID}
	* @param    {PhoneNumberRange} req The API payload object (PhoneNumberRange)
	* @return   {PhoneNumberRange} The API response object (PhoneNumberRange)
	*
	* @typedef  {Object}  PhoneNumberRange
	* @property {String}  UUID           
	* @property {Number}  CreatedAtNanos When the object was first created
	* @property {Number}  UpdatedAtNanos When the object was last updated
	* @property {Number}  DeletedAtNanos When the object was deleted
	* @property {Number}  SPID           Default Service Provider ID
	* @property {String}  NumberType     NumberType (if set, might be like FWA or others, freeform string, can be empty)
	* @property {String}  Description    Description
	* @property {String}  RangeStart     The start of the range
	* @property {String}  RangeEnd       The end of the range
	* @property {Number}  RangeStartInt  The start of the range (represented as a uint64)
	* @property {Number}  RangeEndInt    The end of the range (represented as a uint64)
	* @property {Number}  CooldownLength The length of the cooldown when unallocated
	* @property {Boolean} IsCustomRange  Custom range skips NAD check, allowing range like 15 digits MSISDN
	*
	*/
	UpdatePhoneNumberRange({ getters, rootGetters, commit }, req) {
		// Ensure request is not null or undefined 
		if (!req) { req = {} }
		// Make API request (CRUD Type: Update)
		return axios.put(`/v3/phone_number_range/${req.UUID}`, req).then(response => {
			// Success response
			if (!response.data) {
				return Promise.reject(Error('no response data'))
			}

			// Ensure we save the response in cache
			commit('mutSavePhoneNumberRange', response.data)
			return Promise.resolve(response.data)
		})
			.catch(error => {
				// Error response received from API
				if (error.response) {
					// Server responded with a non-404 HTTP status code
					console.log('UpdatePhoneNumberRange ERROR Response:', error)
					return Promise.reject(error)
				} else if (error.request) {
					// Request was made but no response was received
					console.log('UpdatePhoneNumberRange ERROR making request:', error)
					return Promise.reject(error)
				}
				// Something went wrong in setting up the request
				if (error) {
					console.log('UpdatePhoneNumberRange ERROR:', error)
					return Promise.reject(error)
				}
				console.log('UpdatePhoneNumberRange UNKNOWN ERROR')
				return Promise.reject(Error('unknown error in UpdatePhoneNumberRange'))
			})
	},
};

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions,
};