<template>
     <div id="app" :class="{ 'is-touch-device': is_touch_device }">
        <router-view />

        <app-sidebar-modal :route="$root.sidebar_modal_route" v-if="$root.sidebar_modal_route" />

        <app-spinner class="over-preload full-preloader" v-if="isLoader"/>
     </div>
</template>

<script>
import isTouchDevice from '@/helpers/isTouchDevice';
import AppSpinner from '@/components/app-spinner';

import AppSidebarModal from '@/components/app-sidebar-modal';

import { mapState } from 'vuex';

export default {
    components: {
        AppSpinner,
        AppSidebarModal,
    },
    computed: {
        ...mapState([
            'isLoader'
        ]),
        is_touch_device() {
            return isTouchDevice()
        }
    }
}
</script>

<style lang="scss">
#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;

    .full-preloader {
        z-index: 9991;
        position: fixed;
    }
}
</style>