import { extend } from 'vee-validate';

function mobileNumber() {
    extend("mobileNumber", {
        validate(value) {
            // const regex = new RegExp("^\\+?\\d{9,12}$");
            const regex = new RegExp(/^\+?(02|642)\d{7,}$/);
            return regex.test(value);
        },
        message: field => `${field} should start with 02 or 642 and contains at least 7 numeric characters.`,
    });
}

export default mobileNumber