<template>
    <b-modal id="app-sidebar-modal"
         size="lg"
         scrollable
         centered
         hide-footer
         @hide="onHide"
         :body-class="{ busy: isBusy }"
    >
        <component :is="component" :params="route.params" @loading="loading"/>

        <app-spinner class="over-preload" v-if="isBusy"/>
    </b-modal>
</template>

<script>
const routeByName = (name, routes) => {
    let route = null
    let childrens = null
    const routes_count = routes.length

    if (name && routes_count) {
        for (let i = 0; i < routes_count; i++) {
            if (routes[i]?.name == name) {
                route = routes[i]
                break
            }
        }

        if (!route) {
            childrens = []

            for (let i = 0; i < routes_count; i++) {
                if ('children' in routes[i]) {
                    for (let n = 0, len = routes[i].children.length; n < len; n++) {
                        childrens.push(routes[i].children[n])
                    }
                }
            }
        }
    }

    return route
        ? route
        : childrens
            ? routeByName(name, childrens)
            : null
}

const history = [];

import AppSpinner from '@/components/app-spinner';

export default {
    data() {
        return {
            isBusy: false
        }
    },
    props: {
        route: { type: Object, required: true },
    },
    components: {
        AppSpinner
    },

    watch: {
        $route() {
            this.$bvModal.hide('app-sidebar-modal')
        },

        route(new_route, old_route) {
            if ('$history' in new_route) {
                if (new_route.$history) {
                    history.push(old_route)
                }
            } else {
                history.length = 0
            }
        },
    },

    methods: {
        onHide($event) {
            if (history.length) {
                $event.preventDefault()

                const route = history.pop()
                      route.$history = false

                this.$root.pushSidebarModal(route)
            }
        },
        loading(isBusy) {
            this.isBusy = isBusy;
        }
    },

    computed: {
        _route() {
            return routeByName(this.route.name, this.$router.options.routes)
        },

        component() {
            /**
             * @todo сделать проверку на beforeEnter если такие появятся
             */
            this.isBusy = false;
            return this._route?.component
        },
    },
}
</script>

<style lang="scss">
#app-sidebar-modal {
    &>.modal-dialog {
        align-items: stretch;
        justify-content: flex-end;
        margin: 1.5rem 1.5rem 1.5rem auto;
        height: calc(100% - #{ 2 * 1.5rem });

        .modal-content {
            flex-grow: 1;
            background: $color-details;

            .modal-header {
                position: absolute;
                top: 32px;
                right: 24px;
                z-index: $z-index-btn-close;

                .close {
                    width: 24px;
                    font-size: 30px;
                    line-height: 24px;
                    padding: 0;
                }
            }
            
            .modal-body {
                padding: 24px 24px 0;
                &.busy {
                    overflow: hidden;
                    position: static;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet-size) {
    #app-sidebar-modal {
        &>.modal-dialog {
            max-width: 100%;
            margin: 1.5rem;
        }
    }
}

@media screen and (max-width: $mobile-size) {
    #app-sidebar-modal {
        padding: 0 !important;

        &>.modal-dialog {
            margin: 0;
            height: auto;
            min-height: 100%;
            max-height: 100%;

            .modal-content {
                border-radius: 0;

                .modal-body {
                    padding: 16px 15px 0;
                }
            }
        }
    }
}
</style>