import axios from 'axios';
import axiosUnique from '@/utils/axiosUnique';

import StoreHash from '@/utils/StoreHash';

const cashed = {
    teams: {
        uuids: {},
        lifetime: 1 * 60 * 1000, // 1 min
    },
    teamsAll: new StoreHash(1 * 60 * 1000), // 1 min
    teamsRoles: new StoreHash(5 * 60 * 1000), // 5 min
}

const state = {
    teams: {},
    teamsRoles: {},
}

const getters = {
    getTeamByUUID: state => uuid => state.teams[uuid],
    teams: state => state.teams,
    teamsRoles: state => state.teamsRoles,
}

const mutations = {
    saveTeams(state, { teams }) {
        for (let i = 0, len = teams.length; i < len; i++) {
            const team = teams[i];
            const id = team.UUID;

            // Проверяем есть уже такой teams в кеше, если нет - кешируем
            if (!(id in cashed.teams.uuids)) {
                cashed.teams.uuids[id] = new StoreHash(cashed.teams.lifetime);
            }

            state.teams[id] = team;
            cashed.teams.uuids[id].fix();
            cashed.teamsAll.fix();
        }
    },

    saveTeamsRoles(state, roles) {
        let teamsRoles = {}

        for (let i = 0, len = roles.length; i < len; i++) {
            const role = roles[i]

            teamsRoles[ role.Name ] = role
        }

        state.teamsRoles = teamsRoles
    },
}

const actions = {
    getTeamsAll({ getters, dispatch }) {
        if (cashed.teamsAll.expired()) {
            const params = {
                SPID: getters.spid,
                'SearchOptions.PageSize': -1,
            }

            return dispatch('getTeams', { params })
        } else {
            return Promise.resolve(getters.teams)
        }
    },

    getTeams({commit, dispatch}, { params }) {
        return axiosUnique.get('/v3/auth_role_group', { params }).then(response => {
            const { data } = response;
            const { AuthRoleGroups } = data;

            const teams = AuthRoleGroups;

            commit('saveTeams', { teams });
            return Promise.resolve(response.data);
        }).catch(error => {
            return Promise.reject(error)
        })
    },

    getTeamsRoles({ getters, commit }) {
        /**
         * @todo Replace corresponding lines below for real data
         */

        if (cashed.teamsRoles.expired()) {
            return import('./hardcoded-test-data/teams/teams-roles.json')
                .then(({ Roles }) => {
                    commit('saveTeamsRoles', Roles)
                    return Promise.resolve(getters.teamsRoles)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        } else {
            return Promise.resolve(getters.teamsRoles)
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}