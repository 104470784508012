import { extend } from 'vee-validate';

function password() {
    extend("password", {
        validate(value) {
            return value && value.length >= 8 && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/.test(value)
        },
        message: field => `${field} must contain 8 characters, at least 1 upper/lower case character and number`,
    });
}

export default password