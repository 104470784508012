const REFRESHING_TIME = 30 * 24 * 60 * 60                   // 1 month
const REFRESHING_TIME_COOKIES = 3 * REFRESHING_TIME * 1000  // 3 months

class Storage {
    /**
     * @param {string} priority - "localStorage" or "cookies" (def. localStorage)
     */
    constructor(priority) {
        this.$storage = null

        this.useCookies = false
        this.useLocalStorage = false

        switch (priority) {
            case 'cookies': {
                this.$storage = new StorageCookies()
                this.useCookies = true
            } break

            default: {
                let storage,
                    is_storage_worked = false

                const date = new Date
            
                try {
                    switch (priority) {
                        case 'sessionStorage': { storage = window.sessionStorage } break
                        default:               { storage = window.localStorage } break
                    }
                    storage.setItem(date, date)

                    is_storage_worked = storage.getItem(date) == date

                    storage.removeItem(date)
                } catch (e) {
                    console.warn('e')
                }

                if (is_storage_worked && storage) {
                    this.$storage = new StorageLocal(storage)
                    this.useLocalStorage = true
                }
            }
        }

        if (this.$storage === null) {
            this.$storage = new StorageCookies()
            
            this.useCookies = true
            this.useLocalStorage = false
        }
    }

    get(key, json_parse)                    { return this.$storage.get(key, json_parse)                    }
    set(key, value, expire, json_stringify) { return this.$storage.set(key, value, expire, json_stringify) }
    remove(key)                             { return this.$storage.remove(key)                             }

    getExpiry(key)                          { return this.$storage.getExpiry(key)                          }

    push(key, value, expire) {
        let target = this.$storage.get(key, true)

        if (target) {
            if (Array.isArray(target)) {
                target.push(value)
            } else {
                throw new Error(`Target of pushing "${ key }" is not an array`)
            }
        } else {
            target = [ value ]
        }

        this.$storage.set(key, target, expire, true)
    }

    removeItem(key, value) {
        let target = this.$storage.get(key, true)

        if (target) {
            if (Array.isArray(target)) {
                const index = value instanceof Object
                    ? target.findIndex(item => {
                        let is_match = true

                        for (let key in value) {
                            if (item[key] !== value[key]) {
                                is_match = false
                                break
                            }
                        }

                        return is_match
                    })
                    : target.findIndex(item => item === value)

                if (index > -1) {
                    target.splice(index, 1)

                    this.$storage.set(key, target, null, true)
                }
            } else {
                throw new Error(`Target of removing item "${ key }" is not an array`)
            }
        }
    }
}

/**
 * Interface for working with vue-cookies
 */
class StorageCookies {
    constructor(storage) {
        this.$storage = $cookies
    }

    get(key, json_parse) {
        let value = this.$storage.get(key)

        if (value && json_parse) {
            try {
                value = JSON.parse(value)
            } catch (e) {}
        }

        return value
    }

    getExpiry(key) {
        return this.$storage.getItem(this.expireKey(key))
    }

    set(key, value, expire, json_stringify) {
        this.$storage.set(
            key,
            json_stringify
                ? JSON.stringify(value)
                : value,
            expire === false
                ? new Date(new Date().getTime() + REFRESHING_TIME_COOKIES)
                : expire
                    ? new Date(expire * 1000 + REFRESHING_TIME_COOKIES)
                    : null
        )

        return this
    }
    
    remove(key) {
        this.$storage.remove(key)

        return this
    }
}

/**
 * Interface for working with localStorage
 */
class StorageLocal {
    constructor(storage) {
        this.$storage = storage
    }

    expireKey(key) {
        return `${key}-expire`
    }

    get(key, json_parse) {
        let value = this.$storage.getItem(key)

        if (value) {
            const expiry = this.getExpiry(key)

            if (expiry && (Date.now() > (expiry * 1000))) {
                value = null

                this.remove(key)
            }
        }

        if (value && json_parse) {
            try {
                value = JSON.parse(value)
            } catch (e) {}
        }

        return value
    }

    getExpiry(key) {
        return this.$storage.getItem(this.expireKey(key))
    }

    set(key, value, expire, json_stringify) {
        this.$storage.setItem(
            key,
            json_stringify
                ? JSON.stringify(value)
                : value
        )

        if (expire) {
            this.$storage.setItem(this.expireKey(key), expire + REFRESHING_TIME)
        }

        return this
    }

    remove(key) {
        this.$storage.removeItem(key)
        this.$storage.removeItem(this.expireKey(key))

        return this
    }
}

// export default new Storage('cookies')
// export default new Storage('localStorage')
export default Storage