import Vue from 'vue';
import Vuex from 'vuex';

import modulesLoader from './modules';

Vue.use(Vuex);

const SPID = process.env.VUE_APP_SPID;
// const IDENTITY_PROVIDER = process.env.VUE_APP_IDENTITY_PROVIDER;
const THEME = process.env.VUE_APP_THEME ? process.env.VUE_APP_THEME : 'vodafone';
const PROVIDER_KEY = process.env.VUE_APP_PROVIDER_KEY;
const REDIRHOST = process.env.VUE_APP_REDIRHOST;

const modules = modulesLoader();

export default new Vuex.Store({
    modules,

    state: {
        isLoader: false
    },

    getters: {
        spid: () => parseInt(SPID),
        // identityProvider: () => IDENTITY_PROVIDER,
        theme: () => THEME,
        providerKey: () => PROVIDER_KEY,
        redirHost: () => REDIRHOST,
    },

    mutations: {
        setLoader(state, loader){
            state.isLoader = loader
        }
    },
    actions: {}
})
