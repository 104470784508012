import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

const theme = store.getters.theme;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import vSelect from "vue-select";
Vue.component('v-select', vSelect);

import stylesCSS from '@/assets/scss/styles.scss';

import axiosMiddleware from '@/middlewares/axios-middleware';
// import axiosResellerMiddleware from '@/middlewares/axios-reseller-middleware';

import axios from 'axios';
// import axiosReseller from './utils/axiosReseller';

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.use(axiosMiddleware, axios, store, router);
Vue.prototype.$axios = axios;

// Vue.use(axiosResellerMiddleware, axiosReseller, store, router);
// Vue.prototype.$axiosReseller = axiosReseller;

import directives from '@/directives';
Vue.use(directives);

// Vue Validator
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
localize('en', en);
// install rules
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
import validators from '@/validators';
Vue.use(validators);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Sweetalert
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

import VueEllipseProgress from 'vue-ellipse-progress';
Vue.use(VueEllipseProgress);

// Sidebar modal
import sidebarModal from '@/mixins/sidebarModal'

Vue.use(require('vue-moment'));

Vue.config.productionTip = false;

store.dispatch('loadAuthInfo').then(()=>{}).catch(()=>{});

const themeFonts = import('@/assets/fonts/schemes/' + theme + '.scss');

new Vue({
    mixins: [sidebarModal],
    router,
    store,
    themeFonts,
    stylesCSS,
    render: h => h(App)
}).$mount('#app');
